import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import en from './locale/en.json';
import it from './locale/it.json';

i18n
.use(detector)
.use(reactI18nextModule)
.init({
lng: 'it',
resources: {
	en,
	it,
},
fallbackLng: 'it',
debug: true,
ns: ['translations'],
defaultNS: 'translations',
keySeparator: false,
interpolation: {
	escapeValue: false,
	formatSeparator: ',',
},
react: {
	wait: true,
	useSuspense: false,
},
});

export default i18n;
import React from 'react';
import { TimelineMax, Linear } from 'gsap';
import SvgWaveLines from '../svgWaveLines';

export default class WavesSvg extends React.Component {

    state = {
        tickCount: 0,
        vertex1: [{ a: 1 }, { a: 2 }, { a: 3 }, { a: 4 }, { a: 5 }, { a: 6 }],
        vertex2: [{ a: 1 }, { a: 2 }, { a: 3 }, { a: 4 }, { a: 5 }, { a: 6 }],
        points: [],
    }

    componentDidUpdate(prevProps) {
        const { start, vertecies } = this.props;
        if (start !== prevProps.start) {
            this.startAnimationRandom(vertecies);
        }
    }

    // componentDidMount() {
    //     const w = 90;
    //     const points = [[], []];
    //     for (let i = 0; i < 6; i++) {
    //         points[0][i] = [w * i, 1];
    //         points[1][i] = [w * i, 1];
    //     }

    //     this.setState({
    //         points,
    //     })
    // }

    startAnimationRandom(pointsNew) {
        const { vertex1, vertex2 } = this.state;
        const scaleY = 0.6;
        const delayBlue = 0.5;
        const delayGreen = 0.5;

        const newVerticies = [];
        const newVerticies2 = [];

        for (let x = 0; x < 4; x++) {
            newVerticies[x] = pointsNew[0][x + 1];
            newVerticies2[x] = pointsNew[1][x + 1];
        }


        const tl = new TimelineMax();
        for (let i = 0; i < 4; i++) {
            const element = vertex1[i];

            tl.to(
                element,
                delayGreen,
                {
                    a: newVerticies[i] * scaleY,
                    ease: Linear.easeNone
                },
                0
            );
        }

        const tlBlue = new TimelineMax();
        for (let i = 0; i < 4; i++) {
            const element = vertex2[i];

            tlBlue.to(
                element,
                delayBlue,
                {
                    a: newVerticies2[i] * scaleY,
                    ease: Linear.easeNone
                },
                0
            );
        }

        tl.eventCallback("onUpdate", () => {


            const w = 90;
            const points = [[], []];
            for (let i = 0; i < 6; i++) {
                points[0][i] = [w * i, this.state.vertex1[i].a];
                points[1][i] = [w * i, this.state.vertex2[i].a];
            }

            this.setState({
                points,
            })


        });


    }

    render() {
        const { datasets, options, svg } = this.props;
        const { points } = this.state;
        return (
            <div className="wave-svg">
                <svg>
                    <SvgWaveLines
                        d={datasets[0]}
                        o={options}
                        points={points[0]}
                        svg={svg}
                        tickCount={this.tickCount}
                    />
                    <SvgWaveLines
                        d={datasets[1]}
                        o={options}
                        points={points[1]}
                        svg={svg}
                        tickCount={this.tickCount}
                    />
                </svg>
            </div>
        )
    }
}
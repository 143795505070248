import React, { Component } from 'react'
import './Footer.scss'
import Facebook from '../../assets/Footer/Facebook.svg'
import Globe from '../../assets/Footer/Globe.svg'
import Letter from '../../assets/Footer/Letter.svg'
import Linkedin from '../../assets/Footer/Linkedin.svg'
import Tel from '../../assets/Footer/Tel.svg'
import SSLogo from '../../assets/Footer/StarSysLogo.svg'
import OSLogo from '../../assets/Footer/OsSysLogo.svg'

export class Footer extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className='footer-container'>
                <div className='center-container'>
                    <div className='wrappper-container'>
                        <div className='wr2'>
                            <div className='footer-logo'>
                            <img src={SSLogo} alt='Starsystem Logo'/>
                            </div>
                            <div className='links-container'>
                                <div className='footer-tel footer-link'>
                                    <img src={Tel} alt="Telephone" className='footer-icon'/>
                                    <a href='tel:+39 0461 185 10 40' className='footer-icon-info'>+39 0461 185 10 40</a>
                                </div>
                                <div className='footer-email footer-link'>
                                    <img src={Letter} alt="Letter" className='footer-icon'/>
                                    <a href='mailto:info@starsystem.biz' className='footer-icon-info'>info@starsystem.biz</a>
                                </div>
                                <div className='footer-site footer-link'>
                                    <img src={Globe} alt="Globe" className='footer-icon'/>
                                    <a href="https://starsystem.biz/" className='footer-icon-info'>starsystem.biz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-separator'></div>
                    <div className='wrappper-container'>
                        <div className='wr2'>
                            <div className='footer-logo'>
                                <img src={OSLogo} alt='Ossystem Logo'/>
                            </div>
                            <div className='links-container'>
                                <div className='footer-tel footer-link'>
                                    <img src={Tel} alt="Telephone" className='footer-icon'/>
                                    <a href='tel:+38 095 279 42 12' className='footer-icon-info'>+38 095 279 42 12</a>
                                </div>
                                <div className='footer-email footer-link'>
                                    <img src={Letter} alt="Letter" className='footer-icon'/>
                                    <a href='mailto:office@os-system.com' className='footer-icon-info'>office@os-system.com</a>
                                </div>
                                <div className='footer-site footer-link'>
                                    <img src={Globe} alt="Globe" className='footer-icon'/>
                                    <a href="https://os-system.com/" className='footer-icon-info'>os-system.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='share-container'>
                    <div className='share-sign-container'>
                        <div className='link-separator link-separator-left' />
                        <span className='share-sign'>Share</span>
                        <div className='link-separator' />
                    </div>
                    <div className='icon-container'>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fit-dev.os-system.com%2F&amp;src=sdkpreparse" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt='Facebook' className='icon icon-in'/></a>
                        <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://it-dev.os-system.com/&title=&summary=&source="><img src={Linkedin} alt='Linkedin' className='icon icon-f'/></a>
                    </div>
                </div>
                <div className='bottom-container'>
                    <div className='info-container'>
                        <div className='adress'>{t('footerAdress')}</div>
                        <div className='timetable'>{t('footerTimetable')}</div>                            
                        <div className='schedule'>{t('footerSchedule')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer

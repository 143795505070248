import React, { useRef, useEffect, useState } from 'react'
import './ProjectItem.scss'
import useForm from 'react-hook-form';
import TuEn from '../../assets/ProjectItem/TuEn.svg'
import TuIt from '../../assets/ProjectItem/TuIt.svg'
import Inputmask from "inputmask";
import axios from 'axios';
import Checkmark from '../../assets/ContactUs/Checkmark.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import authHeader from '../../utils/getAuthEmailHeader';

const CaptchaSiteKey = '6LelAdIUAAAAAMHNLxqGFFr-y363nQ3xJfHUctdD';

export default function ProjectItem(props) {

    const stylesOnError = {
        border: '3px solid #B34A4A'
    }

    const stylesWithoutError = {
        border: '3px solid #F5F5F5'
    }

    const visibleStyle = {
        display: 'block'
    }

    const hiddenStyle = {
        display: 'none'
    }

    const { t, lng, resetClickCount, clickCount, onOpenModal, clickHandle } = props;

    const [isFormOk, setForm] = useState(false)

    const toggle = () => setForm(!isFormOk);

    const { register, handleSubmit, errors, getValues, setError, clearError } = useForm({ mode: 'onBlur' });

    const onSubmit = async (data) => {
        const {
            name,
            phoneNumber,
            email,
        } = data;
        const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://edelivery.os-system.com';
        try {
            const resp = await axios.post(apiUrl + '/api/form', {
                name,
                phoneNumber,
                email,
                site: 'it',
            }, { headers: { ...authHeader() }});
            resetClickCount();
            toggle();
            window.gtag_report_conversion(window.location.href);
            if (process.env.NODE_ENV !== 'production') {
                console.log(resp); //eslint-disable-line
            }
        } catch (e) {
            console.log(e);
        } finally {
            window.grecaptcha.reset();
        }
    };

    useEffect(() => {
        let selector = document.getElementById("phone2");
        let im = new Inputmask("+3\\9 9999999999999999", { showMaskOnHover: false, placeholder: " " });
        im.mask(selector);
    }, []);

    const checkboxStyleError = {
        border: '1px solid #DF3333'
    }

    const [verified, setVerification] = useState(false);
    const [captcha, setCaptcha] = useState(true);

    function VerificateCaptcha() {
        setVerification(true);
    }

    function checkFields() {

        const formData = getValues();
        const { name, phoneNumber, email } = formData;

        if (name === '') {
            setError('name');
        } else if (name.length >= 2 && name.length <= 50) {
            clearError('name')
        }

        if (phoneNumber === '') {
            setError('phoneNumber');
        } else if (/^\+39\s\d{1,18}/.test(phoneNumber)) {
            clearError('phoneNumber')
        }

        if (email === '') {
            setError('email');
        } else if (email.length <= 254 && /^\S+@[A-Z0-9]+\.[A-Z]{2,4}$/i.test(email)) {
            clearError('email')
        }

        if (!errors.name && !errors.phoneNumber && !errors.email) {
            clickHandle();
        }
    }

    function BlurCont() {

        const formData = getValues();
        const { name, phoneNumber, email } = formData;

        if (name.length >= 2 && name.length < 50) {
            clearError('name');
        }

        if (/^\+39\s\([0-9]{3}\)-[0-9]{2}-[0-9]{2}-[0-9]{2}$/i.test(phoneNumber)) {
            clearError('phoneNumber');
        }

        if (email.length <= 254 && /^\S+@[A-Z0-9]+\.[A-Z]{2,4}$/i.test(email)) {
            clearError('email');
        }
    }

    function ChangeCont() {

        const formData = getValues();
        const { name, phoneNumber, email } = formData;

        if (name.length >= 2 && name.length < 50) {
            clearError('name');
        }

        if (/^\+39\s\([0-9]{3}\)-[0-9]{2}-[0-9]{2}-[0-9]{2}$/i.test(phoneNumber)) {
            clearError('phoneNumber');
        }

        if (email.length <= 254 && /^\S+@[A-Z0-9]+\.[A-Z]{2,4}$/i.test(email)) {
            clearError('email');
        }
    }

    const nodeForm = useRef();

    // for invisible input
    const handleInvisibleInput=()=>{setCaptcha(!captcha)} 

    const captchaComponent = () => (
      <>
        <div className="captcha-container">
          <input
            name="invisible"
            type="checkbox"
            ref={register({ required: true })}
            className="info-check-invisible"
            checked={verified}
          />
          <ReCAPTCHA
            sitekey={CaptchaSiteKey}
            onChange={VerificateCaptcha}
            hl="en"
          />
          {errors.invisible && !verified && (
            <div className="captcha-error">{t("captchaErr")}</div>
          )}
        </div>

        <div className="personal-information">
          <label className="checkbox-container">
            <input
              name="checkbox"
              type="checkbox"
              ref={register({ required: true })}
              className="info-check"
            />
            <span
              className="checkmark"
              style={errors.checkbox && checkboxStyleError}
            />
            <img src={Checkmark} alt="Arrow" className="checkmark-checked" />
          </label>
          <div className="info-text">
            {t("form-info-text")}
            <span onClick={onOpenModal} className="cookie-policy">
              {" "}
              {t("form-info-link")}
            </span>
          </div>
        </div>
      </>
    );

    return (
        isFormOk ?
            <div className='phone-container'>
                <img src={lng === 'en' ? TuEn : TuIt} alt='Thanks' className='img-tu' />
            </div>
            :
            <div className='phone-container'>
                <div className='fb-mobile' ref={nodeForm}>
                    <div className='fb-mobile-title' style={clickCount === 5 ? visibleStyle : hiddenStyle}>
                        {t('start')}<br />
                        {t('start2')}<br />
                        {t('start3')}<br />
                    </div>
                    <div className='fb-mobile-descr' style={clickCount === 5 ? visibleStyle : hiddenStyle}>
                        {t('intouch')}
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className='form-box fb-mobile-box'>

                    <input id='skipcaptcha-proj' class='invisibleCaptcha' onClick={handleInvisibleInput} type='checkbox'/>
                        <div className='fields-container' style={clickCount === 5 ? visibleStyle : hiddenStyle}>
                            <div className='form-input-holder' >
                                <input style={(errors.name) ? stylesOnError : stylesWithoutError}
                                    name="name"
                                    type="text"
                                    autoComplete="none"
                                    placeholder={t('name')}
                                    ref={register({ required: true, minLength: 2, maxLength: 50 })}
                                    className="form-field"
                                    onBlur={BlurCont}
                                    onChange={ChangeCont}
                                    maxLength="50"
                                />
                                {errors.name && <div className='form-error'>{t('nameErr')}</div>}
                            </div>
                            <div className='form-input-holder' >
                                <input id="phone2" style={(errors.phoneNumber) ? stylesOnError : stylesWithoutError}
                                    name="phoneNumber"
                                    type="tel"
                                    autoComplete="none"
                                    placeholder="+39"
                                    ref={register({required: true, pattern: /\+39\s\d{1,18}/i})}
                                    className="form-field"
                                    maxLength="50"
                                    onBlur={BlurCont}
                                    onChange={ChangeCont}
                                />
                                {errors.phoneNumber && <div className='form-error'>{t('phoneErr')}</div>}
                            </div>
                            <div className='form-input-holder' >
                                <input style={(errors.email) ? stylesOnError : stylesWithoutError}
                                    name="email"
                                    type="email"
                                    autoComplete="none"
                                    placeholder="Email"
                                    ref={register({ required: true, pattern: /^\S+@[A-Z0-9]+\.[A-Z]{2,4}$/i, maxLength: 254 })}
                                    className="form-field"
                                    maxLength="50"
                                    onBlur={BlurCont}
                                    onChange={ChangeCont}
                                />
                                {errors.email && <div className='form-error'>{t('mailErr')}</div>}
                            </div>
                            <button type="button" onClick={checkFields} className='form-field form-button'>{t('next btn')}</button>
                        </div>

                        <div className='fb-mobile-check' style={clickCount === 6 ? visibleStyle : hiddenStyle}>
                            <div className='title-check'>
                                {t('finish1')}<br />
                                {t('finish2')}
                            </div>
                            { captcha && captchaComponent()}
                            <button type="submit" className='form-field form-button'>{t('send')}</button>
                        </div>
                    </form>
                </div>
            </div>
    )
}

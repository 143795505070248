import React from 'react';
import './switch.css';

const Switch = ({ checked, toggleHandler, scroll }) => {
  return (
    <>
      <input
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
        checked={checked}
        onChange={toggleHandler}
      />
      <label
        className="react-switch-label"
        htmlFor={`react-switch-new`}
        style={scroll > 30 ? {background: "#E9E9E9"} : null}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;
import React, { Component } from 'react';
import './ServicesView.scss';
import SummaryLeft from '../../assets/ServicesView/Summary-left.png';
import SummaryRight from '../../assets/ServicesView/Summary-right.png';
import AndroidLogo from '../../assets/ServicesView/icons/Android.svg';
import AppleLogo from '../../assets/ServicesView/icons/Apple.svg';
import CrossPlatform from '../../assets/ServicesView/icons/Cross-platform.svg';
import Gear from '../../assets/ServicesView/icons/Gear.svg';


export default class ServicesView extends Component {
    componentDidMount() {

    }

    render() {
        const { t } = this.props;
        return (
            <div className='container'>
                <div className='title'>
                    <div className='title-part'>{t('development')}</div>
                    <div className='title-separator'></div>
                    <div className='title-part'>{t('flow')}</div>
                </div>
                <div className='phone-cont'>
                    
                    <div className='phone-text phone-text-big'>
                        <div className='phone-text-title'>{t("pm")}</div>
                        <div className='phone-text-ceparator'></div>
                        <div className='phone-text-description'>{t("pm description")}</div>
                    </div>
                        <img className='left-img' alt='Left Summary' src={SummaryLeft}/>
                    <div className='phone-box'>
                        
                    <svg className='phone-img' width="510" height="511" viewBox="0 0 510 511" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d)">
                        <rect x="148" y="30" width="214" height="431" rx="22" fill="white"/>
                        </g>
                        <rect opacity="0.1" x="155" y="38" width="200" height="415" rx="15" fill="#21252E"/>
                        <g className='stroke-on-hover'>
                        <path d="M148 274H155V310H148V274Z" fill="#4E4E4E"/>
                        </g>
                        <path d="M355 228H362V264H355V228Z" fill="#333333"/>
                        <g className='stroke-on-hover'>
                        <path d="M355 228H362V264H355V228Z" fill="#4E4E4E"/>
                        </g>
                        <rect opacity="0.2" x="233" y="434" width="43" height="4" rx="2" fill="#333333"/>
                        <path d="M362 310H446H447C481.9 309.5 510 281 510 246C510 211 481.9 182.5 447 182H446H362V218H446C461.5 218 474 230.5 474 246C474 261.5 461.5 274 446 274H362V310Z" fill="#F2F2F2"/>
                        <g className='big-red-right'>
                            <path className='red-arrows' d="M376.9 197.9L369.6 190.6C369.2 190.2 368.5 190.2 368 190.6C367.6 191 367.6 191.8 368 192.2L374.4 198.6L368 205.1C367.6 205.5 367.6 206.3 368 206.7C368.4 207.1 369.2 207.1 369.6 206.7L376.8 199.5C377.3 199 377.3 198.3 376.9 197.9Z" fill="#D70000"/>
                            <path className='red-arrows' d="M386.4 197.9L379.2 190.7C378.8 190.3 378 190.3 377.6 190.7C377.2 191.1 377.2 191.9 377.6 192.3L384 198.7L377.6 205.1C377.2 205.5 377.2 206.3 377.6 206.7C378 207.1 378.8 207.1 379.2 206.7L386.4 199.5C386.8 199 386.8 198.3 386.4 197.9Z" fill="#D70000"/>
                            <path className='red-arrows' d="M395.8 197.9L388.6 190.7C388.2 190.3 387.4 190.3 387 190.7C386.6 191.1 386.6 191.9 387 192.3L393.4 198.7L387 205.1C386.6 205.5 386.6 206.3 387 206.7C387.4 207.1 388.2 207.1 388.6 206.7L395.8 199.5C396.3 199 396.3 198.3 395.8 197.9Z" fill="#D70000"/>
                            <path className='red-arrows' d="M405.3 197.9L398.1 190.7C397.7 190.3 396.9 190.3 396.5 190.7C396.1 191.1 396.1 191.9 396.5 192.3L402.9 198.7L396.5 205.1C396.1 205.5 396.1 206.3 396.5 206.7C396.9 207.1 397.7 207.1 398.1 206.7L405.3 199.5C405.8 199 405.8 198.3 405.3 197.9Z" fill="#D70000"/>
                            <path className='red-arrows' d="M414.8 197.9L407.6 190.7C407.2 190.3 406.4 190.3 406 190.7C405.6 191.1 405.6 191.9 406 192.3L412.4 198.7L406 205.1C405.6 205.5 405.6 206.3 406 206.7C406.4 207.1 407.2 207.1 407.6 206.7L414.8 199.5C415.2 199 415.2 198.3 414.8 197.9Z" fill="#D70000"/>
                            <path className='red-arrows' d="M424.3 197.9L417 190.6C416.6 190.2 415.8 190.2 415.4 190.6C415 191 415 191.8 415.4 192.2L421.8 198.6L415.4 205C415 205.4 415 206.2 415.4 206.6C415.8 207 416.6 207 417 206.6L424.2 199.4C424.7 199 424.7 198.3 424.3 197.9Z" fill="#D70000"/>
                            <path className='red-arrows' d="M433.7 197.9L426.5 190.7C426.1 190.3 425.3 190.3 424.9 190.7C424.5 191.1 424.5 191.9 424.9 192.3L431.3 198.7L424.9 205.1C424.5 205.5 424.5 206.3 424.9 206.7C425.3 207.1 426.1 207.1 426.5 206.7L433.7 199.5C434.2 199 434.2 198.3 433.7 197.9Z" fill="#D70000"/>
                            <path className='red-arrows' d="M443.2 197.9L436 190.7C435.6 190.3 434.8 190.3 434.4 190.7C434 191.1 434 191.9 434.4 192.3L440.8 198.7L434.4 205.1C434 205.5 434 206.3 434.4 206.7C434.8 207.1 435.6 207.1 436 206.7L443.2 199.5C443.6 199 443.6 198.3 443.2 197.9Z" fill="#D70000"/>
                            <path className='red-arrows' d="M453.1 198.6L446.4 190.9C446 190.4 445.3 190.4 444.8 190.8C444.3 191.2 444.3 191.9 444.7 192.4L450.7 199.3L443.8 205.3C443.3 205.7 443.3 206.4 443.7 206.9C444.1 207.4 444.8 207.4 445.3 207L453 200.3C453.4 199.8 453.5 199 453.1 198.6Z" fill="#D70000"/>
                            <path className='red-arrows' d="M463.6 201.5L458.6 192.5C458.3 191.9 457.6 191.7 457 192.1C456.4 192.4 456.2 193.1 456.6 193.7L461 201.6L453 206.1C452.4 206.4 452.2 207.1 452.6 207.7C452.9 208.3 453.6 208.5 454.2 208.1L463.2 203.1C463.7 202.8 463.9 202.1 463.6 201.5Z" fill="#D70000"/>
                            <path className='red-arrows' d="M473 206.6L469.9 196.8C469.7 196.2 469.1 195.9 468.4 196.1C467.8 196.3 467.5 196.9 467.7 197.6L470.5 206.3L461.8 209.1C461.2 209.3 460.9 209.9 461.1 210.6C461.3 211.2 461.9 211.5 462.6 211.3L472.4 208.2C472.9 207.8 473.2 207.2 473 206.6Z" fill="#D70000"/>
                            <path className='red-arrows' d="M480.6 213.4L479.4 203.2C479.3 202.6 478.8 202.1 478.1 202.2C477.5 202.3 477 202.8 477.1 203.5L478.1 212.5L469.1 213.5C468.5 213.6 468 214.1 468.1 214.8C468.2 215.4 468.7 215.9 469.4 215.8L479.6 214.6C480.3 214.6 480.7 214 480.6 213.4Z" fill="#D70000"/>
                            <path className='red-arrows' d="M486.6 221L487.7 210.8C487.8 210.2 487.3 209.6 486.7 209.5C486.1 209.4 485.5 209.9 485.4 210.5L484.5 219.5L475.5 218.6C474.9 218.5 474.3 219 474.2 219.6C474.1 220.2 474.6 220.8 475.2 220.9L485.4 222C485.9 222.1 486.5 221.6 486.6 221Z" fill="#D70000"/>
                            <path className='red-arrows' d="M490.3 228.3L493.1 218.4C493.3 217.8 492.9 217.1 492.3 217C491.7 216.8 491 217.2 490.9 217.8L488.4 226.5L479.7 224C479.1 223.8 478.4 224.2 478.3 224.8C478.1 225.4 478.5 226.1 479.1 226.2L489 229C489.5 229.3 490.1 228.9 490.3 228.3Z" fill="#D70000"/>
                            <path className='red-arrows' d="M492.7 236.9L497.8 228C498.1 227.4 497.9 226.7 497.4 226.4C496.8 226.1 496.1 226.3 495.8 226.8L491.3 234.7L483.4 230.2C482.8 229.9 482.1 230.1 481.8 230.6C481.5 231.2 481.7 231.9 482.2 232.2L491.1 237.3C491.7 237.6 492.4 237.4 492.7 236.9Z" fill="#D70000"/>
                            <path className='red-arrows' d="M493.1 245.9L500.1 238.5C500.5 238 500.5 237.3 500.1 236.9C499.6 236.5 498.9 236.5 498.5 236.9L492.2 243.5L485.6 237.2C485.1 236.8 484.4 236.8 484 237.2C483.6 237.7 483.6 238.4 484 238.8L491.4 245.8C491.9 246.4 492.7 246.3 493.1 245.9Z" fill="#D70000"/>
                            <path className='red-arrows' d="M492.1 255.8L499.9 249.2C500.4 248.8 500.4 248.1 500 247.6C499.6 247.1 498.9 247.1 498.4 247.5L491.5 253.4L485.6 246.5C485.2 246 484.5 246 484 246.4C483.5 246.8 483.5 247.5 483.9 248L490.5 255.8C490.9 256.2 491.6 256.2 492.1 255.8Z" fill="#D70000"/>
                            <path className='red-arrows' d="M488.7 266L498 261.6C498.6 261.3 498.8 260.6 498.6 260.1C498.3 259.5 497.6 259.3 497.1 259.5L488.9 263.4L485 255.2C484.7 254.6 484 254.4 483.5 254.6C482.9 254.9 482.7 255.6 482.9 256.1L487.3 265.4C487.4 266 488.1 266.2 488.7 266Z" fill="#D70000"/>
                            <path className='red-arrows' d="M483.4 275.2L493.3 272.4C493.9 272.2 494.3 271.6 494.1 271C493.9 270.4 493.3 270 492.7 270.2L483.9 272.7L481.4 263.9C481.2 263.3 480.6 262.9 480 263.1C479.4 263.3 479 263.9 479.2 264.5L482 274.4C482.2 275 482.8 275.4 483.4 275.2Z" fill="#D70000"/>
                            <path className='red-arrows' d="M476.2 282.5L486.5 282.4C487.1 282.4 487.7 281.9 487.6 281.2C487.6 280.6 487.1 280 486.4 280.1L477.3 280.2L477.2 271.1C477.2 270.5 476.7 269.9 476 270C475.4 270 474.8 270.5 474.9 271.2L475 281.5C475.1 282 475.6 282.5 476.2 282.5Z" fill="#D70000"/>
                            <path className='red-arrows' d="M468.6 288.1L478.6 290.3C479.2 290.4 479.8 290 480 289.4C480.1 288.8 479.7 288.2 479.1 288L470.2 286L472.2 277.1C472.3 276.5 471.9 275.9 471.3 275.7C470.7 275.6 470.1 276 469.9 276.6L467.7 286.6C467.6 287.4 468 288 468.6 288.1Z" fill="#D70000"/>
                            <path className='red-arrows' d="M461 291.5L470.7 294.8C471.3 295 472 294.7 472.2 294.1C472.4 293.5 472.1 292.8 471.5 292.6L462.9 289.6L465.9 281C466.1 280.4 465.8 279.7 465.2 279.5C464.6 279.3 463.9 279.6 463.7 280.2L460.4 289.9C460.1 290.6 460.4 291.3 461 291.5Z" fill="#D70000"/>
                            <path className='red-arrows' d="M452.8 293.8L461.7 298.9C462.2 299.2 463 299 463.3 298.5C463.6 298 463.4 297.2 462.9 296.9L455 292.3L459.6 284.4C459.9 283.9 459.7 283.1 459.2 282.8C458.7 282.5 457.9 282.7 457.6 283.2L452.5 292.1C452 292.8 452.2 293.5 452.8 293.8Z" fill="#D70000"/>
                            <path className='red-arrows' d="M443.7 294.4L451.4 301.1C451.9 301.5 452.6 301.5 453 301C453.4 300.5 453.4 299.8 452.9 299.4L446 293.4L452 286.5C452.4 286 452.4 285.3 451.9 284.9C451.4 284.5 450.7 284.5 450.3 285L443.6 292.7C443.2 293.2 443.2 294 443.7 294.4Z" fill="#D70000"/>
                            <path className='red-arrows' d="M434.3 294.1L441.5 301.3C441.9 301.7 442.7 301.7 443.1 301.3C443.5 300.9 443.5 300.1 443.1 299.7L436.7 293.3L443.1 286.9C443.5 286.5 443.5 285.7 443.1 285.3C442.7 284.9 441.9 284.9 441.5 285.3L434.3 292.5C433.9 293 433.9 293.7 434.3 294.1Z" fill="#D70000"/>
                            <path className='red-arrows' d="M424.8 294.1L432 301.3C432.4 301.7 433.2 301.7 433.6 301.3C434 300.9 434 300.1 433.6 299.7L427.2 293.3L433.6 286.9C434 286.5 434 285.7 433.6 285.3C433.2 284.9 432.4 284.9 432 285.3L424.8 292.5C424.4 293 424.4 293.7 424.8 294.1Z" fill="#D70000"/>
                            <path className='red-arrows' d="M415.4 294.1L422.6 301.3C423 301.7 423.8 301.7 424.2 301.3C424.6 300.9 424.6 300.1 424.2 299.7L417.8 293.3L424.2 286.9C424.6 286.5 424.6 285.7 424.2 285.3C423.8 284.9 423 284.9 422.6 285.3L415.4 292.5C414.9 293 414.9 293.7 415.4 294.1Z" fill="#D70000"/>
                            <path className='red-arrows' d="M405.9 294.1L413.1 301.3C413.5 301.7 414.3 301.7 414.7 301.3C415.1 300.9 415.1 300.1 414.7 299.7L408.3 293.3L414.7 286.9C415.1 286.5 415.1 285.7 414.7 285.3C414.3 284.9 413.5 284.9 413.1 285.3L405.9 292.5C405.4 293 405.4 293.7 405.9 294.1Z" fill="#D70000"/>
                            <path className='red-arrows' d="M396.4 294.1L403.6 301.3C404 301.7 404.8 301.7 405.2 301.3C405.6 300.9 405.6 300.1 405.2 299.7L398.8 293.3L405.2 286.9C405.6 286.5 405.6 285.7 405.2 285.3C404.8 284.9 404 284.9 403.6 285.3L396.4 292.5C396 293 396 293.7 396.4 294.1Z" fill="#D70000"/>
                            <path className='red-arrows' d="M387 294.1L394.2 301.3C394.6 301.7 395.4 301.7 395.8 301.3C396.2 300.9 396.2 300.1 395.8 299.7L389.4 293.3L395.8 286.9C396.2 286.5 396.2 285.7 395.8 285.3C395.4 284.9 394.6 284.9 394.2 285.3L387 292.5C386.5 293 386.5 293.7 387 294.1Z" fill="#D70000"/>
                            <path className='red-arrows' d="M377.5 294.1L384.7 301.3C385.1 301.7 385.9 301.7 386.3 301.3C386.7 300.9 386.7 300.1 386.3 299.7L379.9 293.3L386.3 286.9C386.7 286.5 386.7 285.7 386.3 285.3C385.9 284.9 385.1 284.9 384.7 285.3L377.5 292.5C377 293 377 293.7 377.5 294.1Z" fill="#D70000"/>
                            <path className='red-arrows' d="M368 294.1L375.2 301.3C375.6 301.7 376.4 301.7 376.8 301.3C377.2 300.9 377.2 300.1 376.8 299.7L370.4 293.3L376.8 286.9C377.2 286.5 377.2 285.7 376.8 285.3C376.4 284.9 375.6 284.9 375.2 285.3L368 292.5C367.6 293 367.6 293.7 368 294.1Z" fill="#D70000"/>
                        </g>
                        <g clipPath="url(#clip0)" className='big-red-right'>
                        <path d="M148 228L64 228L63 228C28.1 228.5 8.65486e-06 257 5.59506e-06 292C2.53526e-06 327 28.1 355.5 63 356L64 356L148 356L148 320L64 320C48.5 320 36 307.5 36 292C36 276.5 48.5 264 64 264L148 264L148 228Z" fill="#F2F2F2"/>
                        <path className='red-arrows-2' d="M133.1 340.1L140.4 347.4C140.8 347.8 141.5 347.8 142 347.4C142.4 347 142.4 346.2 142 345.8L135.6 339.4L142 332.9C142.4 332.5 142.4 331.7 142 331.3C141.6 330.9 140.8 330.9 140.4 331.3L133.2 338.5C132.7 339 132.7 339.7 133.1 340.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M123.6 340.1L130.8 347.3C131.2 347.7 132 347.7 132.4 347.3C132.8 346.9 132.8 346.1 132.4 345.7L126 339.3L132.4 332.9C132.8 332.5 132.8 331.7 132.4 331.3C132 330.9 131.2 330.9 130.8 331.3L123.6 338.5C123.2 339 123.2 339.7 123.6 340.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M114.2 340.1L121.4 347.3C121.8 347.7 122.6 347.7 123 347.3C123.4 346.9 123.4 346.1 123 345.7L116.6 339.3L123 332.9C123.4 332.5 123.4 331.7 123 331.3C122.6 330.9 121.8 330.9 121.4 331.3L114.2 338.5C113.7 339 113.7 339.7 114.2 340.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M104.7 340.1L111.9 347.3C112.3 347.7 113.1 347.7 113.5 347.3C113.9 346.9 113.9 346.1 113.5 345.7L107.1 339.3L113.5 332.9C113.9 332.5 113.9 331.7 113.5 331.3C113.1 330.9 112.3 330.9 111.9 331.3L104.7 338.5C104.2 339 104.2 339.7 104.7 340.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M95.2 340.1L102.4 347.3C102.8 347.7 103.6 347.7 104 347.3C104.4 346.9 104.4 346.1 104 345.7L97.6 339.3L104 332.9C104.4 332.5 104.4 331.7 104 331.3C103.6 330.9 102.8 330.9 102.4 331.3L95.2 338.5C94.8 339 94.8 339.7 95.2 340.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M85.7 340.1L93 347.4C93.4 347.8 94.2 347.8 94.6 347.4C95 347 95 346.2 94.6 345.8L88.2 339.4L94.6 333C95 332.6 95 331.8 94.6 331.4C94.2 331 93.4 331 93 331.4L85.8 338.6C85.3 339 85.3 339.7 85.7 340.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M76.3 340.1L83.5 347.3C83.9 347.7 84.7 347.7 85.1 347.3C85.5 346.9 85.5 346.1 85.1 345.7L78.7 339.3L85.1 332.9C85.5 332.5 85.5 331.7 85.1 331.3C84.7 330.9 83.9 330.9 83.5 331.3L76.3 338.5C75.8 339 75.8 339.7 76.3 340.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M66.8 340.1L74 347.3C74.4 347.7 75.2 347.7 75.6 347.3C76 346.9 76 346.1 75.6 345.7L69.2 339.3L75.6 332.9C76 332.5 76 331.7 75.6 331.3C75.2 330.9 74.4 330.9 74 331.3L66.8 338.5C66.4 339 66.4 339.7 66.8 340.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M56.9 339.4L63.6 347.1C64 347.6 64.7 347.6 65.2 347.2C65.7 346.8 65.7 346.1 65.3 345.6L59.3 338.7L66.2 332.7C66.7 332.3 66.7 331.6 66.3 331.1C65.9 330.6 65.2 330.6 64.7 331L57 337.7C56.6 338.2 56.5 339 56.9 339.4Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M46.4 336.5L51.4 345.5C51.7 346.1 52.4 346.3 53 345.9C53.6 345.6 53.8 344.9 53.4 344.3L49 336.4L57 331.9C57.6 331.6 57.8 330.9 57.4 330.3C57.1 329.7 56.4 329.5 55.8 329.9L46.8 334.9C46.3 335.2 46.1 335.9 46.4 336.5Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M37 331.4L40.1 341.2C40.3 341.8 40.9 342.1 41.6 341.9C42.2 341.7 42.5 341.1 42.3 340.4L39.5 331.7L48.2 328.9C48.8 328.7 49.1 328.1 48.9 327.4C48.7 326.8 48.1 326.5 47.4 326.7L37.6 329.8C37.1 330.2 36.8 330.8 37 331.4Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M29.4 324.6L30.6 334.8C30.7 335.4 31.2 335.9 31.9 335.8C32.5 335.7 33 335.2 32.9 334.5L31.9 325.5L40.9 324.5C41.5 324.4 42 323.9 41.9 323.2C41.8 322.6 41.3 322.1 40.6 322.2L30.4 323.4C29.7 323.4 29.3 324 29.4 324.6Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M23.4 317L22.3 327.2C22.2 327.8 22.7 328.4 23.3 328.5C23.9 328.6 24.5 328.1 24.6 327.5L25.5 318.5L34.5 319.4C35.1 319.5 35.7 319 35.8 318.4C35.9 317.8 35.4 317.2 34.8 317.1L24.6 316C24.1 315.9 23.5 316.4 23.4 317Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M19.7 309.7L16.9 319.6C16.7 320.2 17.1 320.9 17.7 321C18.3 321.2 19 320.8 19.1 320.2L21.6 311.5L30.3 314C30.9 314.2 31.6 313.8 31.7 313.2C31.9 312.6 31.5 311.9 30.9 311.8L21 309C20.5 308.7 19.9 309.1 19.7 309.7Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M17.3 301.1L12.2 310C11.9 310.6 12.1 311.3 12.6 311.6C13.2 311.9 13.9 311.7 14.2 311.2L18.7 303.3L26.6 307.8C27.2 308.1 27.9 307.9 28.2 307.4C28.5 306.8 28.3 306.1 27.8 305.8L18.9 300.7C18.3 300.4 17.6 300.6 17.3 301.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M16.9 292.1L9.89999 299.5C9.49999 300 9.49999 300.7 9.89999 301.1C10.4 301.5 11.1 301.5 11.5 301.1L17.8 294.5L24.4 300.8C24.9 301.2 25.6 301.2 26 300.8C26.4 300.3 26.4 299.6 26 299.2L18.6 292.2C18.1 291.6 17.3 291.7 16.9 292.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M17.4724 282.898L9.88126 289.853C9.36743 290.284 9.33563 291.011 9.86414 291.502C10.2954 292.016 11.0227 292.048 11.5133 291.519L18.1741 285.403L24.4105 292.138C24.8417 292.652 25.569 292.684 26.0596 292.155C26.5734 291.724 26.6052 290.997 26.0767 290.506L19.1216 282.915C18.7136 282.498 17.9863 282.466 17.4724 282.898Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M19.8106 274.194L11.1469 279.725C10.6191 280.073 10.4846 280.73 10.8589 281.355C11.2074 281.882 11.8646 282.017 12.489 281.643L20.1679 276.686L25.1242 284.365C25.4727 284.893 26.1299 285.027 26.7542 284.653C27.282 284.305 27.4165 283.647 27.0422 283.023L21.5114 274.359C20.9956 273.98 20.3384 273.845 19.8106 274.194Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M24.4119 265.093L14.87 268.892C14.3164 269.143 13.9628 269.756 14.2406 270.406C14.4925 270.96 15.1048 271.313 15.7551 271.036L24.1896 267.74L27.4849 276.175C27.7368 276.728 28.3492 277.082 28.9994 276.804C29.5531 276.552 29.9067 275.94 29.6289 275.29L25.8298 265.748C25.6487 265.072 24.9656 264.841 24.4119 265.093Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M30.1998 257.33L20.0627 258.908C19.4572 258.966 19.0071 259.605 19.0658 260.21C19.1245 260.815 19.7628 261.266 20.3682 261.207L29.2945 259.747L30.7545 268.673C30.8132 269.279 31.4515 269.729 32.0569 269.67C32.6623 269.611 33.1125 268.973 33.0538 268.368L31.4763 258.231C31.4435 257.722 30.8053 257.272 30.1998 257.33Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M36.2936 251.867L26.0062 251.725C25.3749 251.687 24.8023 252.254 24.861 252.86C24.8231 253.491 25.3907 254.064 25.9961 254.005L35.0467 254.168L34.8836 263.219C34.8457 263.85 35.4133 264.422 36.0187 264.364C36.65 264.402 37.2226 263.834 37.1639 263.229L37.3062 252.941C37.4666 252.381 36.9249 251.905 36.2936 251.867Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M43.9794 247.323L34.0626 244.7C33.4054 244.566 32.7811 244.94 32.6207 245.501C32.4862 246.158 32.8606 246.782 33.4212 246.943L42.2167 249.245L39.9147 258.04C39.7802 258.697 40.1545 259.322 40.7152 259.482C41.3724 259.617 41.9967 259.242 42.1571 258.682L44.7798 248.765C44.9143 248.108 44.54 247.483 43.9794 247.323Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M52.569 244.607L43.6095 239.761C43.023 239.504 42.3468 239.685 42.064 240.175C41.807 240.761 41.9882 241.438 42.4781 241.72L50.4838 246.098L46.1066 254.103C45.8497 254.69 46.0308 255.366 46.5207 255.649C47.1072 255.906 47.7834 255.724 48.0662 255.235L52.9124 246.275C53.3626 245.637 53.0589 244.89 52.569 244.607Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M62.3908 243.01L53.9969 237.184C53.4811 236.805 52.805 236.986 52.4255 237.502C52.0461 238.018 52.2273 238.694 52.743 239.073L60.2279 244.211L55.09 251.696C54.7106 252.212 54.8917 252.888 55.4075 253.267C55.9233 253.647 56.5994 253.466 56.9789 252.95L62.8049 244.556C63.0877 244.066 62.9066 243.39 62.3908 243.01Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M73.0908 243.46L65.9112 236.091C65.4328 235.62 64.7057 235.658 64.2977 236.01C63.8275 236.488 63.8654 237.215 64.2169 237.623L70.5666 244.114L64.0758 250.464C63.6056 250.942 63.6435 251.669 63.995 252.077C64.4734 252.548 65.2004 252.51 65.6085 252.158L72.9776 244.979C73.5126 244.689 73.4747 243.962 73.0908 243.46Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M83.7 245.1L76.5 252.3C76.1 252.7 75.3 252.7 74.9 252.3C74.5 251.9 74.5 251.1 74.9 250.7L81.3 244.3L74.9 237.9C74.5 237.5 74.5 236.7 74.9 236.3C75.3 235.9 76.1 235.9 76.5 236.3L83.7 243.5C84.1 244 84.1 244.7 83.7 245.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M93.1 245.1L85.9 252.3C85.5 252.7 84.7 252.7 84.3 252.3C83.9 251.9 83.9 251.1 84.3 250.7L90.7 244.3L84.3 237.9C83.9 237.5 83.9 236.7 84.3 236.3C84.7 235.9 85.5 235.9 85.9 236.3L93.1 243.5C93.6 244 93.6 244.7 93.1 245.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M102.6 245.1L95.4 252.3C95 252.7 94.2 252.7 93.8 252.3C93.4 251.9 93.4 251.1 93.8 250.7L100.2 244.3L93.8 237.9C93.4 237.5 93.4 236.7 93.8 236.3C94.2 235.9 95 235.9 95.4 236.3L102.6 243.5C103.1 244 103.1 244.7 102.6 245.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M112.1 245.1L104.9 252.3C104.5 252.7 103.7 252.7 103.3 252.3C102.9 251.9 102.9 251.1 103.3 250.7L109.7 244.3L103.3 237.9C102.9 237.5 102.9 236.7 103.3 236.3C103.7 235.9 104.5 235.9 104.9 236.3L112.1 243.5C112.5 244 112.5 244.7 112.1 245.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M121.6 245.1L114.3 252.4C113.9 252.8 113.1 252.8 112.7 252.4C112.3 252 112.3 251.2 112.7 250.8L119.1 244.4L112.7 238C112.3 237.6 112.3 236.8 112.7 236.4C113.1 236 113.9 236 114.3 236.4L121.5 243.6C122 244 122 244.7 121.6 245.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M131 245.1L123.8 252.3C123.4 252.7 122.6 252.7 122.2 252.3C121.8 251.9 121.8 251.1 122.2 250.7L128.6 244.3L122.2 237.9C121.8 237.5 121.8 236.7 122.2 236.3C122.6 235.9 123.4 235.9 123.8 236.3L131 243.5C131.5 244 131.5 244.7 131 245.1Z" fill="#D70000"/>
                        <path className='red-arrows-2' d="M140.5 245.1L133.3 252.3C132.9 252.7 132.1 252.7 131.7 252.3C131.3 251.9 131.3 251.1 131.7 250.7L138.1 244.3L131.7 237.9C131.3 237.5 131.3 236.7 131.7 236.3C132.1 235.9 132.9 235.9 133.3 236.3L140.5 243.5C140.9 244 140.9 244.7 140.5 245.1Z" fill="#blue"/>
                      
                        </g>

                        <g clipPath="url(#clip1)">
                            <g className="change-on-hover">
                            <path d="M361.522 228H414.653C437.372 228 455.764 246.369 455.764 269.06C455.764 291.751 437.372 310 414.653 310H361.522V273.982H409.004C409.124 273.982 409.364 273.982 409.484 273.982H414.533C417.298 273.982 419.582 271.701 419.582 268.94C419.582 266.179 417.298 263.898 414.533 263.898H409.605C409.364 263.898 409.244 263.898 409.124 263.898H361.522V228Z" fill="#21252E"/>
                            </g>
                            <path className='white-arrows' d="M364.38 242.618L368.663 247.134L364.142 251.412C363.785 251.768 363.785 252.244 364.142 252.6C364.499 252.957 364.975 252.957 365.332 252.6L370.448 247.728C370.805 247.371 370.805 246.896 370.448 246.539L365.57 241.31C365.213 240.954 364.737 240.954 364.38 241.31C364.023 241.667 364.023 242.261 364.38 242.618Z" fill="white"/>
                            <path className='white-arrows' d="M371.162 242.618L375.565 247.134L371.043 251.412C370.686 251.768 370.686 252.244 371.043 252.6C371.4 252.957 371.876 252.957 372.233 252.6L377.349 247.728C377.706 247.371 377.706 246.896 377.349 246.539L372.352 241.31C371.995 240.954 371.519 240.954 371.162 241.31C370.805 241.667 370.805 242.261 371.162 242.618Z" fill="white"/>
                            <path className='white-arrows' d="M377.944 242.618L382.347 247.134L377.825 251.412C377.468 251.768 377.468 252.244 377.825 252.6C378.182 252.957 378.658 252.957 379.134 252.6L384.37 247.728C384.727 247.371 384.727 246.896 384.37 246.539L379.372 241.31C379.015 240.954 378.539 240.954 378.063 241.31C377.587 241.667 377.587 242.261 377.944 242.618Z" fill="white"/>
                            <path className='white-arrows' d="M384.846 242.618L389.248 247.134L384.608 251.412C384.251 251.768 384.251 252.244 384.608 252.6C384.965 252.957 385.441 252.957 385.916 252.6L391.152 247.728C391.509 247.371 391.509 246.896 391.152 246.539L386.154 241.31C385.797 240.954 385.322 240.954 384.846 241.31C384.489 241.667 384.489 242.261 384.846 242.618Z" fill="white"/>
                            <path className='white-arrows' d="M391.628 242.618L396.03 247.134L391.39 251.412C391.033 251.768 391.033 252.244 391.39 252.6C391.747 252.957 392.342 252.957 392.699 252.6L397.934 247.728C398.291 247.371 398.291 246.896 397.934 246.539L392.937 241.31C392.58 240.954 391.985 240.954 391.628 241.31C391.271 241.667 391.271 242.261 391.628 242.618Z" fill="white"/>
                            <path className='white-arrows' d="M398.41 242.618L402.813 247.134L398.172 251.412C397.815 251.768 397.815 252.244 398.172 252.6C398.529 252.957 399.124 252.957 399.481 252.6L404.716 247.728C405.073 247.371 405.073 246.896 404.716 246.539L399.719 241.31C399.362 240.954 398.767 240.954 398.41 241.31C398.053 241.667 398.053 242.261 398.41 242.618Z" fill="white"/>
                            <path className='white-arrows' d="M405.192 242.618L409.595 247.134L404.954 251.412C404.597 251.768 404.597 252.244 404.954 252.6C405.311 252.957 405.906 252.957 406.263 252.6L411.499 247.728C411.856 247.371 411.856 246.896 411.499 246.539L406.382 241.31C406.025 240.954 405.43 240.954 405.073 241.31C404.954 241.667 404.835 242.261 405.192 242.618Z" fill="white"/>
                            <path className='white-arrows' d="M412.57 242.617L416.853 247.252L412.094 251.412C411.737 251.768 411.737 252.244 411.975 252.6C412.213 252.957 412.808 252.957 413.284 252.719L418.757 247.965C419.114 247.609 419.114 247.133 418.876 246.777L413.997 241.429C413.641 241.072 413.165 241.072 412.689 241.31C412.332 241.667 412.213 242.261 412.57 242.617Z" fill="white"/>
                            <path className='white-arrows' d="M422.684 244.044L425.539 249.748L419.709 252.363C419.233 252.6 419.114 253.076 419.233 253.551C419.352 254.026 419.947 254.264 420.423 254.026L427.086 251.055C427.562 250.818 427.681 250.342 427.562 249.867L424.349 243.331C424.111 242.855 423.635 242.618 423.159 242.855C422.684 243.093 422.446 243.568 422.684 244.044Z" fill="white"/>
                            <path className='white-arrows' d="M431.251 247.728L432.203 254.026L425.896 254.621C425.42 254.739 425.063 255.096 425.182 255.571C425.301 256.047 425.658 256.403 426.253 256.403L433.511 255.69C433.987 255.571 434.344 255.215 434.225 254.739L433.273 247.49C433.154 247.015 432.797 246.658 432.203 246.658C431.608 246.777 431.132 247.252 431.251 247.728Z" fill="white"/>
                            <path className='white-arrows' d="M437.319 252.481L436.248 258.78L430.061 257.354C429.585 257.235 429.109 257.591 428.99 258.067C428.871 258.542 429.228 259.018 429.704 259.136L436.843 260.681C437.319 260.8 437.795 260.444 437.914 259.968L439.223 252.719C439.342 252.244 438.985 251.768 438.509 251.649C437.914 251.649 437.438 252.006 437.319 252.481Z" fill="white"/>
                            <path className='white-arrows' d="M440.889 258.186L438.033 263.89L432.44 260.8C431.965 260.563 431.489 260.681 431.251 261.157C431.013 261.632 431.132 262.108 431.608 262.345L437.914 265.91C438.39 266.148 438.866 266.029 439.104 265.554L442.435 259.018C442.673 258.542 442.554 258.067 442.078 257.829C441.603 257.592 441.008 257.71 440.889 258.186Z" fill="white"/>
                            <path className='white-arrows' d="M442.316 264.722L438.033 269.475L433.511 264.959C433.154 264.603 432.559 264.603 432.203 264.959C431.846 265.316 431.846 265.91 432.203 266.267L437.438 271.377C437.795 271.733 438.39 271.733 438.747 271.377L443.625 265.91C443.982 265.554 443.982 264.959 443.625 264.603C443.268 264.246 442.673 264.365 442.316 264.722Z" fill="white"/>
                            <path className='white-arrows' d="M441.959 272.685L436.605 276.25L433.392 270.664C433.154 270.189 432.559 270.07 432.203 270.427C431.727 270.664 431.608 271.258 431.846 271.734L435.534 278.032C435.772 278.508 436.367 278.627 436.724 278.27L442.911 274.229C443.387 273.992 443.506 273.398 443.268 272.922C442.911 272.566 442.316 272.447 441.959 272.685Z" fill="white"/>
                            <path className='white-arrows' d="M439.58 280.647L433.392 282.667L431.727 276.487C431.608 276.012 431.132 275.774 430.656 275.893C430.18 276.012 429.823 276.487 430.061 277.081L431.846 284.212C431.965 284.687 432.44 284.925 432.916 284.806L439.937 282.548C440.413 282.429 440.77 281.954 440.532 281.36C440.532 280.766 440.056 280.528 439.58 280.647Z" fill="white"/>
                            <path className='white-arrows' d="M435.296 288.015L428.871 288.134L429.109 281.716C429.109 281.241 428.752 280.766 428.276 280.766C427.8 280.766 427.324 281.122 427.324 281.716L426.967 289.084C426.967 289.56 427.324 290.035 427.8 290.035L435.177 289.916C435.653 289.916 436.129 289.56 436.129 288.966C436.248 288.49 435.891 288.015 435.296 288.015Z" fill="white"/>
                            <path className='white-arrows' d="M430.18 293.125L423.873 291.223L426.134 285.162C426.253 284.687 426.134 284.212 425.539 284.093C424.944 283.974 424.468 284.212 424.349 284.687L421.732 291.58C421.613 292.055 421.732 292.53 422.327 292.649L429.466 294.788C429.942 294.907 430.537 294.67 430.656 294.194C430.894 293.719 430.656 293.244 430.18 293.125Z" fill="white"/>
                            <path className='white-arrows' d="M423.04 295.977L417.686 292.293L421.732 287.183C422.089 286.708 421.97 286.232 421.494 285.876C421.018 285.519 420.542 285.638 420.185 286.113L415.544 292.055C415.187 292.531 415.306 293.006 415.782 293.363L421.97 297.522C422.446 297.879 422.922 297.76 423.278 297.284C423.516 296.809 423.397 296.334 423.04 295.977Z" fill="white"/>
                            <path className='white-arrows' d="M415.068 296.69L410.309 292.174L415.187 287.896C415.544 287.539 415.544 287.064 415.187 286.589C414.83 286.232 414.235 286.232 413.878 286.589L408.286 291.461C407.929 291.818 407.929 292.293 408.286 292.768L413.641 297.879C413.997 298.235 414.592 298.235 414.949 297.879C415.425 297.641 415.425 297.047 415.068 296.69Z" fill="white"/>
                            <path className='white-arrows' d="M408.167 296.69L403.408 292.174L408.286 287.896C408.643 287.539 408.643 287.064 408.286 286.589C407.929 286.232 407.334 286.232 406.977 286.589L401.385 291.461C401.028 291.818 401.028 292.293 401.385 292.768L406.739 297.879C407.096 298.235 407.691 298.235 408.048 297.879C408.524 297.641 408.524 297.047 408.167 296.69Z" fill="white"/>
                            <path className='white-arrows' d="M401.385 296.69L396.625 292.174L401.504 287.896C401.861 287.539 401.861 287.064 401.504 286.589C401.147 286.232 400.552 286.232 400.195 286.589L394.603 291.461C394.246 291.818 394.246 292.293 394.603 292.768L399.957 297.879C400.314 298.235 400.909 298.235 401.266 297.879C401.742 297.641 401.742 297.047 401.385 296.69Z" fill="white"/>
                            <path className='white-arrows' d="M394.603 296.69L389.843 292.174L394.722 287.896C395.078 287.539 395.078 287.064 394.722 286.589C394.365 286.232 393.77 286.232 393.413 286.589L387.82 291.461C387.463 291.818 387.463 292.293 387.82 292.768L393.294 297.879C393.651 298.235 394.246 298.235 394.603 297.879C394.959 297.641 394.959 297.047 394.603 296.69Z" fill="white"/>
                            <path className='white-arrows' d="M387.701 296.69L382.942 292.174L387.939 287.896C388.296 287.539 388.296 287.064 387.939 286.589C387.582 286.232 386.987 286.232 386.63 286.589L381.038 291.461C380.681 291.818 380.681 292.293 381.038 292.768L386.511 297.879C386.868 298.235 387.463 298.235 387.82 297.879C388.177 297.641 388.177 297.047 387.701 296.69Z" fill="white"/>
                            <path className='white-arrows' d="M380.919 296.69L376.159 292.174L381.157 287.896C381.514 287.539 381.514 287.064 381.157 286.588C380.8 286.113 380.205 286.232 379.848 286.588L374.137 291.461C373.78 291.817 373.78 292.293 374.137 292.768L379.61 297.878C379.967 298.235 380.562 298.235 380.919 297.878C381.276 297.641 381.276 297.046 380.919 296.69Z" fill="white"/>
                            <path className='white-arrows' d="M374.137 296.69L369.377 292.174L374.375 287.896C374.732 287.539 374.732 287.064 374.375 286.589C373.899 286.232 373.304 286.232 372.947 286.589L367.235 291.461C366.878 291.818 366.878 292.293 367.235 292.768L372.709 297.879C373.066 298.235 373.661 298.235 374.137 297.879C374.494 297.641 374.494 297.047 374.137 296.69Z" fill="white"/>
                            <path className='white-arrows' d="M367.354 296.69L362.476 292.174L367.473 287.896C367.83 287.539 367.83 287.064 367.473 286.589C367.116 286.232 366.522 286.232 366.046 286.589L360.334 291.461C359.977 291.818 359.977 292.293 360.334 292.768L365.808 297.879C366.165 298.235 366.759 298.235 367.235 297.879C367.711 297.641 367.711 297.047 367.354 296.69Z" fill="white"/>
                        </g>
                        
                       
                        <g clipPath="url(#clip2)">
                            <g className='change-on-hover'>
                            <path d="M148.478 310H95.3466C72.6275 310 54.2359 291.631 54.2359 268.94C54.2359 246.249 72.6275 228 95.3466 228H148.478V264.018H100.996C100.876 264.018 100.636 264.018 100.516 264.018H95.4668C92.7021 264.018 90.4181 266.299 90.4181 269.06C90.4181 271.821 92.7021 274.102 95.4668 274.102H100.395C100.636 274.102 100.756 274.102 100.876 274.102H148.478V310Z" fill="#21252E"/>
                            </g>
                            <path className='white-arrows-2' d="M145.62 295.382L141.337 290.866L145.858 286.588C146.215 286.232 146.215 285.756 145.858 285.4C145.501 285.043 145.025 285.043 144.668 285.4L139.552 290.272C139.195 290.629 139.195 291.104 139.552 291.461L144.43 296.69C144.787 297.046 145.263 297.046 145.62 296.69C145.977 296.333 145.977 295.739 145.62 295.382Z" fill="white"/>
                            <path className='white-arrows-2' d="M138.838 295.382L134.435 290.866L138.957 286.588C139.314 286.232 139.314 285.756 138.957 285.4C138.6 285.043 138.124 285.043 137.767 285.4L132.651 290.272C132.294 290.629 132.294 291.104 132.651 291.461L137.648 296.69C138.005 297.046 138.481 297.046 138.838 296.69C139.195 296.333 139.195 295.739 138.838 295.382Z" fill="white"/>
                            <path className='white-arrows-2' d="M132.056 295.382L127.653 290.866L132.175 286.588C132.532 286.232 132.532 285.756 132.175 285.4C131.818 285.043 131.342 285.043 130.866 285.4L125.63 290.272C125.273 290.629 125.273 291.104 125.63 291.461L130.628 296.69C130.985 297.046 131.461 297.046 131.937 296.69C132.413 296.333 132.413 295.739 132.056 295.382Z" fill="white"/>
                            <path className='white-arrows-2' d="M125.154 295.382L120.752 290.866L125.392 286.588C125.749 286.232 125.749 285.756 125.392 285.4C125.035 285.043 124.559 285.043 124.084 285.4L118.848 290.272C118.491 290.629 118.491 291.104 118.848 291.461L123.846 296.69C124.203 297.046 124.678 297.046 125.154 296.69C125.511 296.333 125.511 295.739 125.154 295.382Z" fill="white"/>
                            <path className='white-arrows-2' d="M118.372 295.382L113.97 290.866L118.61 286.588C118.967 286.232 118.967 285.756 118.61 285.4C118.253 285.043 117.658 285.043 117.301 285.4L112.066 290.272C111.709 290.629 111.709 291.104 112.066 291.461L117.063 296.69C117.42 297.046 118.015 297.046 118.372 296.69C118.729 296.333 118.729 295.739 118.372 295.382Z" fill="white"/>
                            <path className='white-arrows-2' d="M111.59 295.382L107.187 290.866L111.828 286.588C112.185 286.232 112.185 285.756 111.828 285.4C111.471 285.043 110.876 285.043 110.519 285.4L105.284 290.272C104.927 290.629 104.927 291.104 105.284 291.461L110.281 296.69C110.638 297.046 111.233 297.046 111.59 296.69C111.947 296.333 111.947 295.739 111.59 295.382Z" fill="white"/>
                            <path className='white-arrows-2' d="M104.808 295.382L100.405 290.866L105.046 286.588C105.403 286.232 105.403 285.756 105.046 285.4C104.689 285.043 104.094 285.043 103.737 285.4L98.5013 290.272C98.1443 290.629 98.1443 291.104 98.5013 291.461L103.618 296.69C103.975 297.046 104.57 297.046 104.927 296.69C105.046 296.333 105.165 295.739 104.808 295.382Z" fill="white"/>
                            <path className='white-arrows-2' d="M97.4304 295.383L93.1468 290.748L97.9063 286.588C98.2633 286.232 98.2633 285.756 98.0253 285.4C97.7873 285.043 97.1924 285.043 96.7165 285.281L91.243 290.035C90.8861 290.391 90.8861 290.867 91.1241 291.223L96.0025 296.571C96.3595 296.928 96.8354 296.928 97.3114 296.69C97.6683 296.333 97.7873 295.739 97.4304 295.383Z" fill="white"/>
                            <path className='white-arrows-2' d="M87.3165 293.956L84.4608 288.252L90.2911 285.637C90.7671 285.4 90.8861 284.924 90.7671 284.449C90.6481 283.974 90.0532 283.736 89.5772 283.974L82.9139 286.945C82.438 287.182 82.319 287.658 82.438 288.133L85.6506 294.669C85.8886 295.145 86.3646 295.382 86.8405 295.145C87.3165 294.907 87.5544 294.432 87.3165 293.956Z" fill="white"/>
                            <path className='white-arrows-2' d="M78.7494 290.272L77.7975 283.974L84.1038 283.379C84.5798 283.261 84.9367 282.904 84.8177 282.429C84.6987 281.953 84.3418 281.597 83.7468 281.597L76.4886 282.31C76.0127 282.429 75.6557 282.785 75.7747 283.261L76.7266 290.51C76.8456 290.985 77.2025 291.342 77.7975 291.342C78.3924 291.223 78.8684 290.748 78.7494 290.272Z" fill="white"/>
                            <path className='white-arrows-2' d="M72.681 285.519L73.7519 279.22L79.9393 280.646C80.4152 280.765 80.8912 280.409 81.0101 279.933C81.1291 279.458 80.7722 278.982 80.2962 278.864L73.157 277.319C72.681 277.2 72.2051 277.556 72.0861 278.032L70.7773 285.281C70.6583 285.756 71.0152 286.232 71.4912 286.351C72.0861 286.351 72.5621 285.994 72.681 285.519Z" fill="white"/>
                            <path className='white-arrows-2' d="M69.1114 279.814L71.9671 274.11L77.5595 277.2C78.0355 277.437 78.5114 277.319 78.7494 276.843C78.9874 276.368 78.8684 275.892 78.3924 275.655L72.0861 272.09C71.6102 271.852 71.1342 271.971 70.8962 272.446L67.5646 278.982C67.3266 279.458 67.4456 279.933 67.9215 280.171C68.3975 280.408 68.9924 280.29 69.1114 279.814Z" fill="white"/>
                            <path className='white-arrows-2' d="M67.6836 273.278L71.9671 268.525L76.4886 273.041C76.8456 273.397 77.4405 273.397 77.7975 273.041C78.1544 272.684 78.1544 272.09 77.7975 271.733L72.562 266.623C72.2051 266.267 71.6101 266.267 71.2532 266.623L66.3747 272.09C66.0177 272.446 66.0177 273.041 66.3747 273.397C66.7317 273.754 67.3266 273.635 67.6836 273.278Z" fill="white"/>
                            <path className='white-arrows-2' d="M68.0405 265.315L73.3949 261.75L76.6076 267.336C76.8456 267.811 77.4405 267.93 77.7975 267.573C78.2734 267.336 78.3924 266.742 78.1544 266.266L74.4658 259.968C74.2279 259.492 73.6329 259.373 73.276 259.73L67.0886 263.771C66.6127 264.008 66.4937 264.602 66.7316 265.078C67.0886 265.434 67.6836 265.553 68.0405 265.315Z" fill="white"/>
                            <path className='white-arrows-2' d="M70.4202 257.353L76.6076 255.333L78.2734 261.513C78.3924 261.988 78.8684 262.226 79.3443 262.107C79.8203 261.988 80.1772 261.513 79.9393 260.919L78.1544 253.788C78.0355 253.313 77.5595 253.075 77.0836 253.194L70.0633 255.452C69.5873 255.571 69.2304 256.046 69.4683 256.64C69.4683 257.234 69.9443 257.472 70.4202 257.353Z" fill="white"/>
                            <path className='white-arrows-2' d="M74.7038 249.985L81.1291 249.866L80.8911 256.284C80.8911 256.759 81.2481 257.234 81.724 257.234C82.2 257.234 82.6759 256.878 82.6759 256.284L83.0329 248.916C83.0329 248.44 82.6759 247.965 82.2 247.965L74.8228 248.084C74.3468 248.084 73.8709 248.44 73.8709 249.034C73.7519 249.51 74.1089 249.985 74.7038 249.985Z" fill="white"/>
                            <path className='white-arrows-2' d="M79.8203 244.875L86.1266 246.777L83.8658 252.838C83.7469 253.313 83.8658 253.788 84.4608 253.907C85.0557 254.026 85.5317 253.788 85.6506 253.313L88.2684 246.42C88.3873 245.945 88.2684 245.47 87.6734 245.351L80.5342 243.212C80.0582 243.093 79.4633 243.33 79.3443 243.806C79.1064 244.281 79.3443 244.756 79.8203 244.875Z" fill="white"/>
                            <path className='white-arrows-2' d="M86.9595 242.023L92.3139 245.707L88.2684 250.817C87.9114 251.292 88.0304 251.768 88.5063 252.124C88.9823 252.481 89.4582 252.362 89.8152 251.887L94.4557 245.945C94.8127 245.469 94.6937 244.994 94.2177 244.637L88.0304 240.478C87.5544 240.121 87.0785 240.24 86.7215 240.716C86.4835 241.191 86.6025 241.666 86.9595 242.023Z" fill="white"/>
                            <path className='white-arrows-2' d="M94.9317 241.31L99.6911 245.826L94.8127 250.104C94.4557 250.461 94.4557 250.936 94.8127 251.411C95.1696 251.768 95.7646 251.768 96.1215 251.411L101.714 246.539C102.071 246.182 102.071 245.707 101.714 245.232L96.3595 240.121C96.0025 239.765 95.4076 239.765 95.0506 240.121C94.5747 240.359 94.5747 240.953 94.9317 241.31Z" fill="white"/>
                            <path className='white-arrows-2' d="M101.833 241.31L106.592 245.826L101.714 250.104C101.357 250.461 101.357 250.936 101.714 251.411C102.071 251.768 102.666 251.768 103.023 251.411L108.615 246.539C108.972 246.182 108.972 245.707 108.615 245.232L103.261 240.121C102.904 239.765 102.309 239.765 101.952 240.121C101.476 240.359 101.476 240.953 101.833 241.31Z" fill="white"/>
                            <path className='white-arrows-2' d="M108.615 241.31L113.375 245.826L108.496 250.104C108.139 250.461 108.139 250.936 108.496 251.411C108.853 251.768 109.448 251.768 109.805 251.411L115.397 246.539C115.754 246.182 115.754 245.707 115.397 245.232L110.043 240.121C109.686 239.765 109.091 239.765 108.734 240.121C108.258 240.359 108.258 240.953 108.615 241.31Z" fill="white"/>
                            <path className='white-arrows-2' d="M115.397 241.31L120.157 245.826L115.278 250.104C114.922 250.461 114.922 250.936 115.278 251.411C115.635 251.768 116.23 251.768 116.587 251.411L122.18 246.539C122.537 246.182 122.537 245.707 122.18 245.232L116.706 240.121C116.349 239.765 115.754 239.765 115.397 240.121C115.041 240.359 115.041 240.953 115.397 241.31Z" fill="white"/>
                            <path className='white-arrows-2' d="M122.299 241.31L127.058 245.826L122.061 250.104C121.704 250.461 121.704 250.936 122.061 251.411C122.418 251.768 123.013 251.768 123.37 251.411L128.962 246.539C129.319 246.182 129.319 245.707 128.962 245.232L123.489 240.121C123.132 239.765 122.537 239.765 122.18 240.121C121.823 240.359 121.823 240.953 122.299 241.31Z" fill="white"/>
                            <path className='white-arrows-2' d="M129.081 241.31L133.841 245.826L128.843 250.104C128.486 250.461 128.486 250.936 128.843 251.412C129.2 251.887 129.795 251.768 130.152 251.412L135.863 246.539C136.22 246.183 136.22 245.707 135.863 245.232L130.39 240.122C130.033 239.765 129.438 239.765 129.081 240.122C128.724 240.359 128.724 240.954 129.081 241.31Z" fill="white"/>
                            <path className='white-arrows-2' d="M135.863 241.31L140.623 245.826L135.625 250.104C135.268 250.461 135.268 250.936 135.625 251.411C136.101 251.768 136.696 251.768 137.053 251.411L142.765 246.539C143.122 246.182 143.122 245.707 142.765 245.232L137.291 240.121C136.934 239.765 136.339 239.765 135.863 240.121C135.506 240.359 135.506 240.953 135.863 241.31Z" fill="white"/>
                            <path className='white-arrows-2' d="M142.646 241.31L147.524 245.826L142.527 250.104C142.17 250.461 142.17 250.936 142.527 251.411C142.884 251.768 143.478 251.768 143.954 251.411L149.666 246.539C150.023 246.182 150.023 245.707 149.666 245.232L144.192 240.121C143.835 239.765 143.241 239.765 142.765 240.121C142.289 240.359 142.289 240.953 142.646 241.31Z" fill="white"/>
                        </g>
                        <rect x="193" y="31" width="123" height="17" rx="8.5" fill="white"/>
                        <rect opacity="0.2" x="237" y="37" width="35" height="3" rx="1.5" fill="#333333"/>
                        <defs>
                        <filter id="filter0_d" x="108" y="0" width="294" height="511" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dy="10"/>
                        <feGaussianBlur stdDeviation="20"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        <clipPath id="clip0">
                        <rect width="148" height="128" fill="white" transform="translate(148 356) rotate(-180)"/>
                        </clipPath>
                        </defs>
                    </svg>

                            <div className='phone-block-holder'>
                            <div className='phone-block pb-1'>
                                <div className='phone-block-bg'></div>
                                <div className='phone-block-title'>Exploration</div>
                            </div>
                            <div className='phone-block pb-2'>
                                <div className='phone-block-bg'></div>
                                <div className='phone-block-title'>Assesment</div>
                            </div>
                            <div className='phone-block pb-3'>
                                <div className='phone-block-bg'></div>
                                <div className='phone-block-title'>Design</div>
                            </div>
                            <div className='phone-block pb-4'>
                                <div className='phone-block-bg'></div>
                                <div className='phone-block-title'>Development</div>
                            </div>
                            <div className='phone-block pb-5'>
                                <div className='phone-block-bg'></div>
                                <div className='phone-block-title'>QA</div>
                            </div>
                            <div className='phone-block pb-6'> 
                                <div className='phone-block-bg'></div>
                                <div className='phone-block-title'>Deployment</div>
                            </div>
                            <div className='phone-block pb-7'>
                                <div className='phone-block-bg'></div>
                                <div className='phone-block-title'>Profit</div>
                            </div>
                        </div>
                    </div>
                        <img className='right-img' alt='Right Summary' src={SummaryRight}/>
                    <div className='small-container'>
                        <div className='phone-text small-phone-text'>
                            <div className='phone-text-title'>{t("pm")}</div>
                            <div className='phone-text-ceparator'></div>
                            <div className='phone-text-description'>{t("pm description")}</div>
                        </div>
                        <div className='phone-text'>
                            <div className='phone-text-title'>{t("team")}</div>
                            <div className='phone-text-ceparator'></div>
                            <div className='phone-text-description'>{t("team description")}</div>
                        </div>
                    </div>
                </div>
                <div className='icons-container'>

                    <div className='icons-box'>
                        <div className='icon-holder'>
                          <object className='icons-box-icon' aria-labelledby="Android" data={AndroidLogo}/>
                          <div className='shadow'></div>
                        </div>
                        <div className='icons-box-title'>{t("android")}</div>
                    </div>


                    <div className='icons-box'>
                        <div className='icon-holder'> 
                            <object className='icons-box-icon'aria-labelledby="Apple"  data={AppleLogo}/>
                            <div className='shadow'></div>
                        </div>
                        <div className='icons-box-title'>{t("iOS")}</div>
                    </div>


                    <div className='icons-box'>
                        <div className='icon-holder'>
                            <object className='icons-box-icon' aria-labelledby="CrossPlatform" data={CrossPlatform}/>
                            <div className='shadow'></div>
                       </div>
                        <div className='icons-box-title'>{t("cross")}<br/> {t("platform")}</div>
                    </div>


                    <div className='icons-box'>
                        <div className='icon-holder'>
                            <object className='icons-box-icon' aria-labelledby="Gear" data={Gear}/>
                            <div className='shadow'></div>
                        </div>
                        <div className='icons-box-title'>{t("refining")}  <br /> {t("optimization")}</div>
                    </div>

                </div>
            </div>
        )
    }
}

import React from 'react'
import './LatestProjects.scss'
import Mobile from '../../assets/LatestProjects/Union.svg';
import MZLogo from '../../assets/LatestProjects/project-logos/Megazone.svg'
import LDLogo from '../../assets/LatestProjects/project-logos/LoveDate.svg'
import IRLogo from '../../assets/LatestProjects/project-logos/IllychReal.svg'
import FATLogo from '../../assets/LatestProjects/project-logos/FoodAT.svg'
import Fade from 'react-reveal/Fade';
import MZVid from '../../assets/LatestProjects/project-videos/video1.mp4'
import IRVid from '../../assets/LatestProjects/project-videos/IlichRealty.mp4'
import DAVid from '../../assets/LatestProjects/project-videos/Dating_eng.mp4'    
import FDVid from '../../assets/LatestProjects/project-videos/Fooddelivery.mp4'


const LatestProjects = (props) => {

        const { t } = props;
        
        return (
            <div className='container-lp'>
                <div className='title-lp'>
                    <div className='title-part-lp'>{t('latest')}</div>
                    <div className='title-separator-lp'></div>
                    <div className='title-part-lp'>{t('projects2')}</div>
                </div>
                <div className='projects-container'>
                    <div className='project-item'>
                        <div className='phone-holder phone-holder-left phone-big-size'>
                            <div className='img-holder'>
                                <img className='phone-holder-img' alt='Phone' src={Mobile}/>
                                <video className='phone-video' autoPlay playsInline loop preload muted>
                                   <source type="video/mp4"  src={MZVid} />
                                </video>
                            </div>
                            <Fade left big duration='1200'>
                                <div className='horizontal-black horizontal-left'></div>
                            </Fade>
                            <Fade top big duration='1200'>
                                <div className='vertical-red left-vertical'></div>
                            </Fade>
                        </div>
                        <div className='project-info item-left'>
                            <Fade right>
                            <img src={MZLogo} alt='MegaZone' className='project-name' />
                            </Fade>
                            <Fade left>
                            <div className='project-purpose'>{t("online store")}</div>
                            </Fade>
                            <div className='phone-holder phone-holder-left phone-small-size'> 
                                <div className='img-holder'>
                                    <img className='phone-holder-img' alt='Phone' src={Mobile}/>
                                    <video className='phone-video' autoPlay playsInline loop preload muted>
                                    <source type="video/mp4"  src={MZVid} />
                                    </video>
                                </div>

                                <Fade left big duration='1200'>
                                <div className='horizontal-black horizontal-left'></div>
                                </Fade>
                                <Fade top big duration='1200'>
                                <div className='vertical-red left-vertical'></div>
                                </Fade>
                           </div>
                           <Fade left>
                            <div className='project-description'>{t("mz-descr")}</div>
                            <div className='title-bold'>{t("platform2")}</div>
                            <div className='description'>iOS / Android </div>

                            <div className='title-bold'>{t("technology")}</div>
                            <div className='description'>React Native, Node.js, MongoDB</div>

                            <div className='title-bold'>{t("team2")}</div>
                            <div className='description'>
                                1 {t("designer")}<br/> 
                                2 React Native {t("developers")}<br/>  
                                2 {t("back-end dev")}<br/> 
                                1 {t("qa")}<br/> 
                                1 {t("pm2")}
                            </div>
                            <div className='title-bold'>{t("duration")}</div>
                            <div className='description'>4 {t("months")}</div>

                            <div className='title-bold'>{t("client")}</div>
                            <div className='description'>Enterprise</div>
                            </Fade>
                        </div>
                    </div>

                    <div className='project-item '>
                        <div className='project-info item-right'>
                            <Fade left>
                            <img src={LDLogo} alt='LoveDate' className='project-name'/>
                            </Fade>
                            <Fade right>
                            <div className='project-purpose'>{t("datapp")}</div>
                            </Fade>
                            <div className='phone-holder phone-holder-right phone-small-size'>
                                <div className='img-holder'>
                                    <img className='phone-holder-img' alt='Phone' src={Mobile}/>
                                    <video className='phone-video' autoPlay playsInline loop preload muted>
                                    <source type="video/mp4"  src={DAVid} />
                                    </video>
                                </div>
                                <Fade right big duration='1200'>
                                <div className='horizontal-black right-horizontal'></div>
                                </Fade>
                                <Fade top big duration='1200'>
                                    <div className='vertical-red right-vertical'></div>
                                </Fade>
                            </div>
                            <Fade right>
                            <div className='project-description'>{t("da-descr")}</div>

                            <div className='title-bold'>{t("platform2")}</div>
                            <div className='description'>iOS / Android</div>

                            <div className='title-bold'>{t("technology")}</div>
                            <div className='description'>React Native, Node.js, Sencha ExtJS, MariaDB, AWS, Firebase, Google Maps API</div>

                            <div className='title-bold'>{t("team2")}</div>
                            <div className='description'>
                                1 {t("designer")}<br/> 
                                3 {t("back-end dev")}<br/> 
                                3 React Native {t("developers")}<br/> 
                                1 {t("qa")}<br/> 
                                1 {t("pm2")}<br/> 
                            </div>

                            <div className='title-bold'>{t("duration")}</div>
                            <div className='description'>6 {t("months")}</div>

                            <div className='title-bold'>{t("client")}</div>
                            <div className='description'>{t("startup")}</div>
                            </Fade>
                           </div>
                        <div className='phone-holder phone-holder-right phone-big-size'>
                            <div className='img-holder'>
                                <img className='phone-holder-img' alt='Phone' src={Mobile}/>
                                <video className='phone-video' autoPlay playsInline loop preload muted>
                                <source type="video/mp4"  src={DAVid} />
                                </video>
                            </div>
                            <Fade right big duration='1200'>
                                <div className='horizontal-black right-horizontal'></div>
                            </Fade>
                            <Fade top big duration='1200'>
                                <div className='vertical-red right-vertical'></div>
                            </Fade>
                        </div>
                    </div>

                    <div className='project-item '>
                        <div className='phone-holder phone-holder-left phone-big-size' >
                                <div className='img-holder'>
                                    <img className='phone-holder-img' alt='Phone' src={Mobile}/>
                                    <video className='phone-video' autoPlay playsInline loop preload muted>
                                    <source type="video/mp4"  src={IRVid} />
                                    </video>
                                </div>
                                <Fade left big duration='1200'>
                                    <div className='horizontal-black horizontal-left'></div>
                                </Fade>
                                <Fade top big duration='1200'>
                                    <div className='vertical-red left-vertical'></div>
                                </Fade>
                        </div>
                        <div className='project-info item-left'>
                            <Fade right>
                            <img src={IRLogo} alt='SuperRealty' className='project-name logo-realty-small' />
                            </Fade>
                            <Fade left>
                            <div className='project-purpose'>{t("rent")}</div>
                            </Fade>

                            <div className='phone-holder phone-holder-left phone-small-size'>
                                <div className='img-holder'>
                                    <img className='phone-holder-img' alt='Phone' src={Mobile}/>
                                    <video className='phone-video' autoPlay playsInline loop preload muted>
                                    <source type="video/mp4"  src={IRVid} />
                                    </video>
                                </div>
                                <Fade left big duration='1200'>
                                    <div className='horizontal-black horizontal-left'></div>
                                </Fade>
                                <Fade top big duration='1200'>
                                    <div className='vertical-red left-vertical'></div>
                                </Fade>
                           </div>

                           <Fade left>
                            <div className='project-description'>
                               {t("rent-descr")}
                            </div>

                            <div className='title-bold'>{t("platform2")}</div>
                            <div className='description'>iOS / Android </div>

                            <div className='title-bold'>{t("technology")}</div>
                            <div className='description'> React Native, PHP (Symfony),Google Maps API, Redis </div>
                            <div className='title-bold'>{t("team2")}</div>
                            <div className='description'>
                                1 {t("designer")}<br/> 
                                1 {t("back-end dev")}<br/> 
                                2 React Native {t("developers")}<br/> 
                                1 {t("qa")}<br/> 
                                1 {t("pm2")}<br/> 
                            </div>
                            <div className='title-bold'>{t("duration")}</div>
                            <div className='description'>1,5 {t("months")}</div>

                            <div className='title-bold'>{t("client")}</div>
                            <div className='description'>{t("enterprise")}</div>
                            </Fade>
                        </div>
                    </div>

                    <div className='project-item'>
                        <div className='project-info item-right'>
                            <Fade left>
                            <img src={FATLogo} alt='FoodDelivery' className='project-name fat-logo'/>
                            </Fade>
                            <Fade right>
                            <div className='project-purpose'>{t("FAT")}</div>
                            </Fade>
                            <div className='phone-holder phone-holder-right phone-small-size'>
                                <div className='img-holder'>
                                    <img className='phone-holder-img' alt='Phone' src={Mobile}/>
                                    <video className='phone-video' autoPlay playsInline loop preload muted>
                                    <source type="video/mp4"  src={FDVid} />
                                    </video>
                                </div>
                                <Fade right big duration='1200'>
                                    <div className='horizontal-black right-horizontal'></div>
                                </Fade>
                                <Fade top big duration='1200'>
                                    <div className='vertical-red right-vertical'></div>
                                </Fade>
                           </div>
                           <Fade right>
                            <div className='project-description'>
                            {t("FAT-descr")}
                            </div>

                            <div className='title-bold'>{t("platform2")}</div>
                            <div className='description'>iOS / Android</div>

                            <div className='title-bold'>{t("technology")}</div>
                            <div className='description'>
                                React Native, Node.js, AWS, MongoDB 
                            </div>

                            <div className='title-bold'>{t("team2")}</div>
                            <div className='description'>
                                1 {t("designer")}<br/> 
                                1 {t("back-end dev")}<br/> 
                                2 Front-end {t("developers")}<br/> 
                                2 {t("qa")}<br/> 
                                1 {t("pm2")}<br/> 
                            </div>

                            <div className='title-bold'>{t("duration")}</div>
                            <div className='description'>6 {t("months")}</div>

                            <div className='title-bold'>{t("client")}</div>
                            <div className='description'>{t("asc")}</div>
                            </Fade>
                        </div>
                        <div className='phone-holder phone-holder-right phone-big-size'>
                                <div className='img-holder'>
                                    <img className='phone-holder-img' alt='Phone' src={Mobile}/>
                                    <video className='phone-video' autoPlay playsInline loop preload muted>
                                    <source type="video/mp4"  src={FDVid} />
                                    </video>
                                </div>
                                <Fade right big duration='1200'>
                                    <div className='horizontal-black right-horizontal'></div>
                                </Fade>
                                <Fade top big duration='1200'>
                                    <div className='vertical-red right-vertical'></div>
                                </Fade>
                        </div>
                    </div>
                </div>
            </div>
        )
}



export default LatestProjects;


    
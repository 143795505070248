import './App.scss';
import MainView from '../MainView';
import ServicesView from '../ServicesView';
import LatestProjects from '../LatestProjects';
import Reviews from '../Reviews';
import ContactUs from '../ContactUs';
import Footer from '../Footer';
import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import LogoLoad from '../../assets/OS.png';
import CookieBanner from '../CookieBanner';
import Modal from 'react-responsive-modal';
import ModalText from '../ModalText';

class App extends Component {

  constructor() {
    super();
    this.state = {
      count: 0,
      imageStatus: false,
      bgStatus: false,
      open: false,
      isClicked: false
    };
    
  }

  componentDidMount () {
    if(sessionStorage.getItem("allowCookie")) {
      this.setState({
        isClicked: true
      })
    }
  }

  onAccept = () => {
    sessionStorage.setItem('allowCookie', true);
    this.setState({
      isClicked: true
  })
    // ReactGA.initialize('UA-68778562-4');
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }
 
  handleImgLoaded = () => {
    this.setState({
      imageStatus: true
    })
  }

  handleBgLoaded = () => {
    this.setState({
      bgStatus: true
    })
  }

  addNumber = () => {
    this.setState(prevState => ({ count: prevState.count + 1 }));
  };

  changeLang = lang => {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.onAccept();
    this.setState({ open: false });
  };

  render() {
    if (this.props.test ==='it' && this.props.lng==='en') {
      this.props.i18n.changeLanguage('it')
    }
    if (this.props.test ==='en' && this.props.lng==='it') {
      this.props.i18n.changeLanguage('en')
    }

    const { open, isClicked } = this.state;
    
    const ShowPreloder = {
      visibility: 'visible',
      overflow: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      width: '100%',
      height: '100%'
    }

    const HidePreloaderB = {
      visibility: 'hidden',
      overflow: 'hidden',
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      top: '0'

    }

    const HidePreloader = {
      display: 'none'
    }

    const ShowPreloderB = {
      display: 'flex'
    }

    return (
    <div className='app-container'>
        <div className='loader-container' style={this.state.imageStatus ? HidePreloader : ShowPreloderB}>
          <div className="loading"> 
          </div>
          <img src={LogoLoad} alt="loading" />
        </div>
        <div style={this.state.imageStatus ? ShowPreloder : HidePreloaderB}>
          <Modal open={open} animationDuration={0} onClose={this.onCloseModal} onAccept={this.onAccept} { ...this.props} center>
            <ModalText onCloseModal={this.onCloseModal} onAccept={this.onAccept} { ...this.props}/>
          </Modal>
          <CookieBanner onOpenModal={this.onOpenModal} open={open} onAccept={this.onAccept} isClicked={isClicked} { ...this.props}/>
          <section id='main'>
            <MainView changeLang={this.changeLang} onOpenModal={this.onOpenModal} handleImgLoaded={this.handleImgLoaded} { ...this.props}/>
          </section>
          <section id='services'>
            <ServicesView { ...this.props}/>
          </section>
          <section id='projects'>
            <LatestProjects  { ...this.props}/>
          </section>
          <section id='reviews'>
            <Reviews { ...this.props}/>
          </section>
          <section id='contact'>
            <ContactUs onOpenModal={this.onOpenModal} { ...this.props}/>
          </section>
          <Footer { ...this.props}/>
        </div>
    </div>
    )
  }
}

export default withNamespaces("translations")(App)

import React, { useRef, useEffect, useState } from 'react'
import './ContactUs.scss'
import useForm from 'react-hook-form';
import axios from 'axios';
import TuEn from '../../assets/ProjectItem/TuEn.svg';
import TuIt from '../../assets/ProjectItem/TuIt.svg';
import Inputmask from 'inputmask';
import Checkmark from '../../assets/ContactUs/Checkmark.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import authHeader from '../../utils/getAuthEmailHeader';

const CaptchaSiteKey = '6LelAdIUAAAAAMHNLxqGFFr-y363nQ3xJfHUctdD';

export default function ContactUs(props) {

    const { onOpenModal } = props;

    const stylesOnError = {
        border: '3px solid #B34A4A'
    }

    const stylesWithoutError = {
        border: '3px solid #F5F5F5'
    }

    const checkboxStyleError = {
        border: '1px solid #DF3333'
    }

    const hiddenStyle = {
        display: 'none'
    }

    const visibleStyle = {
        display: 'flex'
    }

    const displayNone = {
        display: 'none'
    }

    const displayFull = {
        display: 'flex'
    }

    const [ isCuOk, setCu ] = useState(false)

    const toggle = (smth) => setCu(smth);

    const { t, lng } = props;

    const { register, handleSubmit, errors, reset, } = useForm();
    const onSubmit = async (data, e) => {
        const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://edelivery.os-system.com';
        toggle(true);
        const {
            name,
            phoneNumber,
            email,
        } = data;
        try {
            const resp = await axios.post(apiUrl + '/api/form', {
                name,
                phoneNumber,
                email,
                site: 'it',
                sendFrom: window?.location?.origin || '',
            }, { headers: { ...authHeader() }});
            reset();
            window.gtag_report_conversion(window.location.href);
            if (process.env.NODE_ENV !== 'production') {
                console.log(resp); //eslint-disable-line
            }
        } catch (e) {
            console.log(e);
        } finally {
            window.grecaptcha.reset();
        }
        setTimeout(() => {
            toggle(false);
        }, 3000);

    };

    const node = useRef();

    const [verified, setVerification] = useState(false);
    const [captcha, setCaptcha] = useState(true);

    function VerificateCaptcha() {
        setVerification(true);
    }

    useEffect(() => {
        let selector = document.getElementById('phone');
        let im = new Inputmask("+3\\9 9999999999999999", { showMaskOnHover: false, placeholder: " " });
        im.mask(selector);
    }, []);

    function isTextInput(node) {
        return ['INPUT'].indexOf(node.nodeName) !== -1;
    }
    document.addEventListener('touchstart', function(e) {
        if (!isTextInput(e.target) && isTextInput(document.activeElement)) {
        document.activeElement.blur();
        }
    }, false);

    // for invisible input
    const handleInvisibleInput=()=>{setCaptcha(!captcha)} 

    const captchaComponent = () => (
      <>
        <div className="captcha-container">
          <input
            name="invisible"
            type="checkbox"
            ref={register({ required: true })}
            className="info-check-invisible"
            checked={verified}
            readOnly
          />
          <ReCAPTCHA
            sitekey={CaptchaSiteKey}
            onChange={VerificateCaptcha}
            hl="en"
          />
          {errors.invisible && !verified && (
            <div className="captcha-error">{t("captchaErr")}</div>
          )}
        </div>
        <div className="personal-information">
          <label className="checkbox-container">
            <input
              name="checkbox"
              type="checkbox"
              ref={register({ required: true })}
              className="info-check"
            />
            <span
              className="checkmark"
              style={errors.checkbox && checkboxStyleError}
            />
            <img src={Checkmark} alt="Arrow" className="checkmark-checked" />
          </label>
          <div className="info-text">
            {t("form-info-text")}
            <span onClick={onOpenModal} className="cookie-policy">
              {" "}
              {t("form-info-link")}
            </span>
          </div>
        </div>
      </>
    );
    return (
        <div className='background-container'>
        <div className='contact-container'>
            <div className='form-container' ref={node}>
                <div className='hidden-container' style={isCuOk ? hiddenStyle : visibleStyle}>
                    <div className='form-title'>{t('contactus')}</div>
                    <div className={lng === 'en' ? 'form-description' : 'form-description it-desc'}>
                        {
                            lng === 'en' ?
                            t('cudescr') :
                            t('cudescr')
                        }
                        <br/>
                        {
                            lng === 'en' ?
                            t('cudescr2') :
                            null
                        }
                    </div>
                        <form onSubmit={handleSubmit(onSubmit)} className='form-box'>
                        <input id='skipcaptcha-cont'  class='invisibleCaptcha' onClick={handleInvisibleInput} type='checkbox'/>
                        <div className='form-input-holder'>
                            <input style={(errors.name) ? stylesOnError : stylesWithoutError}
                                name='name'
                                type='text'
                                placeholder={t('name')}
                                ref={register({required: true, minLength: 2, maxLength: 50})}
                                className='form-field'
                                maxLength='50'
                        />
                            {errors.name && <div className='form-error'>{t('nameErr')}</div>}
                        </div>
                        <div className='form-input-holder'>
                            <input id='phone' style={(errors.phoneNumber) ? stylesOnError : stylesWithoutError}
                                name='phoneNumber'
                                type='tel'
                                placeholder='+39'
                                ref={register({required: true, pattern: /\+39\s\d{1,18}/i})}
                                className='form-field'
                                maxLength='50'
                            />
                            {errors.phoneNumber && <div className='form-error'>{t('phoneErr')}</div>}
                        </div>
                        <div className='form-input-holder'>
                            <input style={(errors.email) ? stylesOnError : stylesWithoutError}
                                name='email'
                                type='text'
                                placeholder='Email'
                                ref={register({required: true, pattern: /^\S+@\S+$/i, maxLength: 50})}
                                className='form-field'
                                maxLength='50'
                            />
                            {errors.email && <div className='form-error'>{t('mailErr')}</div>}
                        </div>

                        {captcha && captchaComponent()}

                        <button type='submit' className='form-field form-button'>{t('send2')}</button>
                    </form>
                    <div className='form-copyright'>
                        © OS-system, {new Date().getFullYear()}
                    </div>
                </div>
                <div className='contact-us-tu' style={isCuOk ? displayFull : displayNone}>
                    <img src={lng==='en' ? TuEn : TuIt} alt='Thanks' className='cu-img-tu'  />
                </div>
            </div>
        </div>
    </div>
    )
}

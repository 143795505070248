import React from 'react';

import './CookieBanner.scss';

const CookieBanner = (props) => {
const { t, onOpenModal, open, isClicked, onAccept } = props;

return (
    <div className="cookie-banner" style={{display: isClicked ? 'none' : open ? 'none' : null, width: open ? 'calc(100%+15px)' : '100%'}}>
        <div className='cookie-wrapper'>
            <div className='cb-info'>{t('cookie')} <span onClick={onOpenModal} className='cookie-policy'>Read Cookie Policy</span>
            </div>

            <div className='cookie-buttons'>
                <button onClick={onAccept} className='ck-btn' >OK</button>
            </div>

        </div>
    </div>
)
}

export default CookieBanner;

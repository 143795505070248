import React, { Component } from 'react'
import './Reviews.scss'
import Slider from '../Slider'

export default class Reviews extends Component {
    render() {
        const { lng, t } = this.props;

        return (
            <div className='container-rv'>
                <div className='title-rv'>
                    <div className='title-part-rv'>{t("customer")}</div>
                    <div className={lng==='en' ? 'title-separator-rv' : 'title-separator-rv separator-it'}></div>
                    <div className='title-part-rv'>{t("reviews2")}</div>
                </div>
                <Slider />
            </div>
        )
    }
}

import React from 'react';
import ChartSvg from '../chartSvg';
import WaveSvg from '../wavesSvg';
import './game.scss';
import dashIt from '../../assets/MainView/dashboardIt.svg'
import dashEn from '../../assets/MainView/dashboardEn.svg'

class Game extends React.Component {

    state = {
        vertexPoints: [[], []],
        vertexPointsWave: [[], []],
        taskStart: false,

        datasets: [
            {
                name: 'one',
                colors: {
                    path: "#00D63D",
                    circles: 'transparent'
                }
            },
            {
                name: 'two',
                colors: {
                    path: '#DB281A',
                    circles: 'transparent'
                }
            }
        ],
        options: {
            xMin: 0,
            xMax: 300,
            yMin: 0,
            yMax: 100,
            line: {
                smoothing: 0.2,
                flattening: 0.1
            }
        },
    }

    componentDidMount() {
        setTimeout(() => {
            this.startAnimation();
        }, 1000)
    }

    generateRandomData() {
        const randomYGreen = [];
        const randomYBlue = [];

        for (let i = 1; i < 7; i++) {
            randomYGreen[i] = 10 + Math.floor(Math.random() * 120);
            randomYBlue[i] = 10 + Math.floor(Math.random() * 120);

            while (Math.abs(randomYGreen[i] - randomYBlue[i]) < 20) {
                randomYBlue[i] = 10 + Math.floor(Math.random() * 120);
            }
        }

        return [randomYGreen, randomYBlue];
    }

    startAnimation = () => {
        let { vertexPoints, vertexPointsWave, taskStart } = this.state;
        vertexPoints = this.generateRandomData();
        vertexPointsWave = vertexPoints;
        this.setState({
            vertexPoints,
            vertexPointsWave,
            taskStart: !taskStart,
        })
        this.props.clickHandle();
    }

    render() {
        const { lng, t, clickCount } = this.props;

        const { taskStart, vertexPoints, vertexPointsWave, datasets, options } = this.state;

        return (
            <div className="phone-container">
                <img src={lng==='en' ? dashEn : dashIt} alt="Dashboard" className="dash-img" />
                <ChartSvg
                    className="charts-pos"
                    start={taskStart}
                    vertecies={vertexPoints} />

                    <WaveSvg
                        start={taskStart}
                        datasets={datasets}
                        vertecies={vertexPointsWave}
                        options={options}
                        svg={{ w: 250, h: 100 }} />


                    <button
                        id="task-start"
                        className="task-start"
                        onClick={() => this.startAnimation()}
                    >
                        {t("start btn")} {clickCount !== 0 ? clickCount : 1}
                    </button>
            </div>
        )
    }
}


export default Game;
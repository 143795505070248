import React, { Component } from 'react'
import './MainView.scss'
import Header from '../Header'
import phone from '../../assets/Form/TelBlank.svg'
import Circle from '../../assets/MainView/Ellipse.svg'
import LittleCircle from '../../assets/MainView/Circle.svg';
import Triangle from '../../assets/MainView/Polygon.svg';
import Game from '../game'
import ProjectItem from '../ProjectItem/ProjectItem';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import bgImage from '../../assets/background.png'
import bgImageSm from '../../assets/MainView/bg-small.svg'

export default class MainView extends Component {
    constructor(props) {
        super(props)
        this.greyCont = React.createRef();
        this.state = {
            width: window.innerWidth, 
            clickCount: 0
        }
        this.updateDimensions = this.updateDimensions.bind(this);
    }


    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    
        const img = new Image();
        img.src = this.state.width <= 599 ? bgImageSm : bgImage;
        img.onload = () => {
            setTimeout(() => {
                this.props.handleImgLoaded();
            }, 1000);
           
        }
    }


     componentWillUnmount() {
        clearInterval(this.interval);
    }


    clickHandle = () => {
        let { clickCount } = this.state;
        this.setState({
            clickCount: ++clickCount,
        });
    }
    
    updateDimensions() {
        this.setState({
          width: window.innerWidth
        });
      }

    resetClickCount = () => {
        setTimeout(() => {
            this.setState({ clickCount: 0 });
        }, 3000);
    }

    render() {
        const { changeLang, t, lng, onOpenModal } = this.props
        const { clickCount } = this.state;

        const bgStyleBig = {
            backgroundImage:  `url(${bgImage})`
        }

        const bgStyleSm = {
            backgroundImage:  `url(${bgImageSm})`
        }


        return (
            <div className='red-container'>
                <div ref={this.greyCont} style={this.state.width <=634 ? bgStyleSm : bgStyleBig }className='grey-container'>
                    <Header changeLang={changeLang} {...this.props}/>
                    <div className='main-container'>
                        <div className='content-container'>
                            <div className='phone-container'>
                            {this.state.clickCount > 4 ? 
                            <ProjectItem 
                                onOpenModal={onOpenModal} 
                                resetClickCount={this.resetClickCount} 
                                clickCount={clickCount} 
                                clickHandle={this.clickHandle}
                                { ...this.props}
                            /> 
                        : 
                            <Game 
                                clickHandle={this.clickHandle} 
                                clickCount={clickCount}
                                { ...this.props} 
                            />
                            }
                                <img src={phone} alt='Phone' className='phone-img img-abs' />
                                <img src={Circle} alt='Circle' className='phone-circle' />
                                <img src={Triangle} alt='Triangle' className='phone-triangle' />
                                <img src={LittleCircle} alt='Circle' className='phone-little-circle' />
                            </div>
                            <div className='info-container'>
                                <img src={Triangle} alt='Triangle' className='phone-triangle-sm' />
                                <img src={LittleCircle} alt='Circle' className='phone-little-circle-sm' />
                               <div className='info-box'>
                                   <div className='info-box-title-create'>{t('We create')}</div>
                                   <div className='info-box-title-apps'>{t('apps')}</div>
                                   <div className='info-box-title-create create2'>{t('cross platform')} <br/> <span>{t('cross platform 2')}</span></div>
                                   <div className='info-box-description'>{t('approach')}</div>
                                    <AnchorLink offset='0' href='#contact'>
                                        {
                                            this.state.width > 1060 ?
                                            <div className={lng === 'en' ? 'info-box-button' : 'info-box-button btn-it' }>{t('quote')}</div>
                                            :
                                            this.state.width <= 1060 && this.state.width > 945 ? 
                                            <div className={lng === 'en' ? 'info-box-button' : 'info-box-button btn-it' }>{lng === 'en' ? t('quote') : t('quote sm')}<br/>{lng === 'en' ? null : t('quote sm2')}</div>
                                            :
                                            this.state.width <= 945 && this.state.width > 634 ? 
                                            <div className={lng === 'en' ? 'info-box-button' : 'info-box-button btn-it' }>{lng === 'en' ? t('quote') : t('quote xs')}<br/>{lng === 'en' ? null : t('quote xs2')}</div>
                                            :  this.state.width <= 634  && this.state.width > 450 ? 
                                            <div className={lng === 'en' ? 'info-box-button' : 'info-box-button btn-it' }>{t('quote')}</div>
                                            : this.state.width <= 450 ?
                                            <div className={lng === 'en' ? 'info-box-button' : 'info-box-button btn-it' }>{lng === 'en' ? t('quote') : t('quote sm')}<br/>{lng === 'en' ? null : t('quote sm2')}</div>
                                            : null
                                        }
                                       
                                    </AnchorLink>
                               </div>
                               <div className={lng==='en' ? 'stats-box' : 'stats-box ib-italy'}> 
                                   <div className='stats-box-item'>
                                       <span className='stats-box-item-num'>15+</span><br style={{display: 'none'}}/>
                                       <div className='stats-box-text'>{t('experience')}</div>
                                   </div>
                                   <div className='stats-box-item'>
                                       <span className='stats-box-item-num'>40+</span><br style={{display: 'none'}}/>
                                       <div className='stats-box-text'>{t('engineers')}</div>
                                   </div>
                                   <div className='stats-box-item'>
                                       <span className='stats-box-item-num'>120+</span><br style={{display: 'none'}}/>
                                       <div className='stats-box-text'>{t('clients')}</div>
                                   </div>
                               </div>
                            </div>

                        </div>
                    </div>
                    <AnchorLink offset='40' href='#services'>
                    <div className='arrow-and-scroll'>
                        <div className="scroll-downs">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                        </div>
                        <div className='arrow'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    </AnchorLink>
                </div>
            </div>
        )
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './normalize.css'
import App from './componets/App'
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";



ReactDOM.render(<I18nextProvider i18n={i18n}>
  <Router>
    <Switch>
      <Route path="/en">
         <App test={'en'}/>
      </Route> 
      <Route path="/"> 
         <App test={'it'}/>
      </Route>
    </Switch>
  </Router>

  </I18nextProvider>, document.getElementById('root'));





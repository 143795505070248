import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Header.scss'
import Logo from '../../assets/starsys_logo.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Switch from '../switch';
import { Link } from "react-router-dom";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            top: 0,
            checked: false,
        };
        this.headerRef = React.createRef()
        this.handleScroll = this.handleScroll.bind(this);
    }


    handleScroll() {
        this.setState({ scroll: window.scrollY });
    }



    componentDidMount() {
        //Detect scrolling and add padding to container to prevent jumping content
        const el = this.headerRef.current;
        this.setState({ height: el.offsetHeight });
        window.addEventListener('scroll', this.handleScroll);
        const greyContainer = document.querySelector('.grey-container')
        this.state.scroll > this.state.top ?
            greyContainer.style.paddingTop = `${this.state.height}px` :
            greyContainer.style.paddingTop = 0;
    }

    componentDidUpdate(prevProps) {
        //Sticky Header
        const { lng } = this.props;
        if (lng !== prevProps.lng) {
            this.setState({ checked: lng === 'en' ? true : false })
        }
        const greyContainer = document.querySelector('.grey-container')
        this.state.scroll > this.state.top ?
            greyContainer.style.paddingTop = `${this.state.height}px` :
            greyContainer.style.paddingTop = 0;
    }

    toggleHandler = () => {
        this.setState({ checked: !this.state.checked });
        this.props.history.push(!this.state.checked ? '/en' : '/');
    }


    render() {
        const { t, lng, test } = this.props;
        const { changeLang } = this.props;
        const { checked, scroll } = this.state;

        return (
            <div className={this.state.scroll > this.state.top ? "header-container fixed-nav" : 'header-container'} ref={this.headerRef}>
                <div className='header-center'>
                    <div className='header-logo'>
                        <AnchorLink href='#main'>
                            <img className='header-logo-img' alt='Logo' src={Logo} />
                        </AnchorLink>
                        <div className='logo-descr'>{t('logo descr')}
                            <a className='logo-descr-span' href="https://os-system.com/" target="_blank" rel="noopener noreferrer">{t('logo descr2')}</a>
                        </div>
                    </div>                    
                    <div className='header-menu'>
                        <div className='header-menu-lgs'>
                            <Link to='/' className={lng === 'it' && test === 'it' || test === 'it' ? 'header-menu-lg lg-it active-lng' : 'header-menu-lg lg-it'} onClick={() => { changeLang('it') }} >IT</Link>
                            <Switch
                                toggleHandler={this.toggleHandler}
                                checked={checked}
                                scroll={scroll}
                            />
                            <Link to='/en' className={lng === 'en' && test === 'en' || test === 'en' ? 'header-menu-lg lg-en active-lng' : 'header-menu-lg lg-en'} onClick={() => { changeLang('en') }} >EN</Link>
                        </div>
                        <div className='header-menu-nav'>
                            <AnchorLink offset='40' href='#services'>
                                <div className='header-menu-nav-item nav-services' >{t('services')}</div>
                            </AnchorLink>
                            <AnchorLink offset='50' href='#projects'>
                                <div className='header-menu-nav-item nav-projects' >{t('projects')}</div>
                            </AnchorLink>
                            <AnchorLink offset='50' href='#reviews'>
                                <div className='header-menu-nav-item nav-reviews' >{t('reviews')}</div>
                            </AnchorLink>
                        </div>
                        <AnchorLink offset='0' href='#contact'>
                            <div
                                className={lng === 'it' ? 'header-menu-contact cont-it' : 'header-menu-contact'}
                            >
                                {t('contact')}
                            </div>
                        </AnchorLink>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Header);

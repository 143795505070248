import React from 'react';
import './ModalText.scss';

class ModalText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenList1: false,
            isOpenList2: false
        }
    }


    onOpenList = (index) => {
        let { isOpenList1, isOpenList2 } = this.state;
        if (index === 1) {
            this.setState({
                isOpenList1: !isOpenList1
            })
        } 
        if (index === 2) {
            this.setState({
                isOpenList2: !isOpenList2
            })
        }
        
    }

    render() {
       const { onCloseModal } = this.props;
        return (
            <div className='modal-main'>
                <div className='modal-wrapper'>
                    <div className='modal-padding' />
                    <div className='modal-center'>
                        <p className='header'>Privacy</p>
                        <p>In questa pagina si descrivono le modalità di gestione del sito internet www.starsystem.biz in riferimento al trattamento dei dati personali degli utenti che lo consultano.</p>

                        <p>Si tratta di un’informativa per il trattamento dei dati personali che è resa ai sensi degli artt. 13 e 14 del <a href='http://eur-lex.europa.eu/legal-content/IT/TXT/PDF/?uri=CELEX:32016R0679&amp;from=IT' target='blank' rel='noopener'> Regolamento UE n. 679/2016</a> (<a href='http://eur-lex.europa.eu/legal-content/IT/TXT/PDF/?uri=CELEX:32016R0679&amp;from=IT' target='blank' rel='noopener'>GDPR</a>) per gli utenti dei servizi del nostro sito, che vengono erogati via internet. L’informativa non è valida per altri siti web eventualmente consultabili attraverso i nostri links, salvo, limitatamente ai siti di proprietà di Starsystem IT S.r.l., nei casi in cui sia espressamente richiamata tramite apposito link.</p>

                        <p className='sub-header'>1. Il Titolare del trattamento</p>

                        <p>Titolare del trattamento dei dati personali, relativi a persone fisiche, identificate o identificabili, trattati a seguito della consultazione del nostro sito, è STARSYSTEM IT Srl – Località Fratte 49 – c/o CENTRO COMMERCIALE PONTE REGIO – Pergine Valsugana (TN)</p>

                        <p className='sub-header'>2. Finalità del trattamento dei dati, base giuridica di liceità del trattamento stesso e fonte dei dati</p>

                        <p>I dati personali, forniti dagli utenti che inoltrano richieste di servizi, sono utilizzati al fine di:</p>

                        <p>a) fornire le informazioni richieste e/o svolgere trattative e/o altre attività necessarie alla fase precontrattuale a richiesta dell’interessato, ovvero eseguire eventuali contratti conclusi con l’interessato, ai sensi dell’art. 6, paragrafo 1, lett. b), del GDPR;</p>

                        <p>b) per motivi relativi ad adempimenti di obblighi contabili, fiscali e di altri obblighi a cui è soggetto il titolare in base alle normativa vigenti, ai sensi dell’art. 6, paragrafo 1, lett. c), del GDPR;</p>

                        <p>c) per l’invio di newsletter, informazioni commerciali e materiale pubblicitario di prodotti e servizi forniti dal titolare, anche a mezzo della posta elettronica, previo consenso dell’interessato, ai sensi dell’art. 6, paragrafo 1, lett. a), del GDPR;</p>

                        <p>d) per motivi tecnici legati al funzionamento del sito web, compresi i cookies tecnici, previo consenso dell’interessato, ai sensi della Direttiva 2009/136/CE del 25.09.2009, del D.Lgs. 28.05.2012, n. 69 e del Provvedimento del Garante per la Protezione dei dati personali n. 229 dell’08.05.2014 (Individuazione delle modalità semplificate per l’informativa e l’acquisizione del consenso per l’uso dei cookie).</p>

                        <p>Ove i dati personali non siano stati forniti dall’interessato a seguito di richiesta di informazioni inviata da quest’ultimo, ovvero nella fase di trattative e conclusione di contratti e/o in fase di esecuzione degli stessi, i dati possono provenire, per le sole finalità di cui al punto 2, lett. c), da fonti accessibili al pubblico (elenchi e registri pubblici, siti web aziendali e/o istituzionali, pagine pubbliche di social network, elenchi telefonici).</p>
                        <p className='sub-header'>3. Tipi di dati trattati</p>

                        <p>a) Dati forniti volontariamente dagli utenti</p>

                        <p>L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, così come l’acquisizione degli eventuali altri dati personali (comuni) inseriti nella missiva o forniti nelle eventuali successive attività connesse alle trattative precontrattuali e/o alla conclusione e all’esecuzione di contratti.</p>

                        <p>Non viene richiesto all’interessato la fornitura di dati sensibili, ossia appartenenti a categorie particolari, come descritti dall’art. 9 del GDPR, ossia di dati che rivelino l’origine razziale o etnica, le opinioni politiche, le convinzioni religione o filosofiche, o l’appartenenza sindacale, o che trattino dati genetici, o dati biometrici intesi a identificare in modo univoco una persona fisica, o dati relativi alla salute o alla vita sessuale o all’orientamento sessuale della persona; né la fornitura di dati giudiziari descritti dall’art. 10 del GDPR.</p>

                        <p>b) Dati di navigazione</p>

                        <p>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.</p>

                        <p>In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, l’orario della richiesta.</p>

                        <p>Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione. I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito.</p>

                        <p>c) Cookies</p>

                        <p>Per cookies si intende un elemento testuale che viene inserito nel disco fisso di un computer solo in seguito ad autorizzazione. I cookies hanno la funzione di snellire l’analisi del traffico su web o di segnalare quando un sito specifico viene visitato e consentono alle applicazioni web di inviare informazioni a singoli utenti. Nessun dato degli utenti viene in proposito acquisito dal sito. Non viene fatto uso di cookies per la trasmissione di informazioni di carattere personale, né vengono utilizzati c.d. cookies persistenti di alcun tipo, ovvero sistemi per il tracciamento degli utenti. Per maggiori informazioni consulta l’apposita <span className='modal-underline'>informativa cookies</span> al <a href='https://www.starsystem.biz/cookies/'>seguente indirizzo</a>.</p>

                        <p className='sub-header'>4. Luogo e soggetti autorizzati al trattamento</p>

                        <p>I trattamenti connessi ai servizi web di questo sito hanno luogo presso la sede del nostro fornitore di servizi internet (nominato incaricato esterno per la manutenzione e responsabile del trattamento).</p>

                        <p>I trattamenti connessi alle trattative e alle altre attività precontrattuali a richiesta dell’interessato e alla conclusione ed esecuzione di contratti possono aver luogo anche presso la sede del titolare.</p>

                        <p>I dati personali possono essere conosciuti dal titolare del trattamento e dai dipendenti e/o collaboratori del titolare nella loro qualità di responsabili o autorizzati al trattamento.</p>

                        <p className='sub-header'>5. Comunicazione di dati</p>

                        <p>Nessun dato derivante dal servizio web viene comunicato.</p>

                        <p>Eventuali ulteriori dati personali possono essere comunicati per le finalità di cui ai punto 2, lett. a-b, ai seguenti soggetti, aventi la funzione di titolare o responsabile:</p>

                        <p>a) istituti di credito;</p>

                        <p>b) consulenti (commercialisti, centri di assistenza fiscale, ecc..)</p>

                        <p>c) aziende di trasporti e di logistica;</p>

                        <p>d) società esterne di assistenza informatica;</p>

                        <p>e) compagnie assicurative;</p>

                        <p>f) in genere, a tutti quei soggetti pubblici e privati cui la comunicazione sia necessaria per il corretto adempimento delle finalità indicate nel punto 2, lett. a-b.</p>

                        <p>I dati personali non sono soggetti a diffusione.</p>

                        <p className='sub-header'>6. Comunicazione di dati all’estero</p>

                        <p>I dati personali non potranno essere trasmessi a Paesi terzi rispetto all’Unione Europea o a organizzazioni internazionali.</p>

                        <p className='sub-header'>7. Modalità di trattamento dei dati</p>

                        <p>I dati personali derivanti dal servizio web sono trattati con strumenti informatici nei modi e nei limiti necessari a conseguire lo scopo per il quale sono stati raccolti.</p>

                        <p>Eventuali ulteriori dati, raccolti per le finalità di cui al punto 2, lett. a-b) possono essere trattati con o senza l’ausilio di strumenti elettronici, informatici o comunque automatizzati.</p>

                        <p>I dati personali non sono oggetto di un processo decisionale automatizzato, ivi compresa la profilazione.</p>

                        <p>Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.</p>

                        <p className='sub-header'>8. Tempi di conservazione dei dati</p>

                        <p>I dati personali, oggetto di trattamento saranno conservati:</p>

                        <p>a) per le finalità sopra indicate al punto 2, lett. a)-b), per il periodo di durata strettamente necessario a fornire le informazioni richieste e/o a svolgere trattative e/o altre attività necessarie alla fase precontrattuale a richiesta dell’interessato, ovvero ad eseguire eventuali contratti conclusi con l’interessato; e successivamente per il tempo in cui StarSystem IT S.r.l. sia soggetta a obblighi di conservazione per finalità fiscali o per altre finalità, previsti da norme di legge o regolamento e, comunque fino all’intervenuta definitiva prescrizione dei diritti e degli obblighi eventualmente derivanti dal rapporto contrattuale o extracontrattuale e/o dalle trattative tra le parti avviate a richieste dell’interessato;</p>

                        <p>b) con riferimento alle finalità di cui al precedente punto 2, lett. c), per due anni</p>

                        <p>c) con riferimento alle finalità di cui al precedente punto 2, lett. d), per il tempo indicato nell’informativa relativa ai cookies. Per maggiori informazioni consultare il seguente indirizzo.</p>

                        <p className='sub-header'>9. Facoltatività del conferimento dei dati. Conseguenze del mancato conferimento</p>

                        <p>A parte quanto specificato per i dati di navigazione, l’utente è libero di fornire i dati personali per richiedere i servizi offerti dalla società o comunque indicati nei contatti per eventuali richieste e/o comunicazioni, ma è necessario per le finalità di cui ai punti 2 a) e b); infatti, la loro mancata indicazione potrebbe non consentirci di far ottenere all’utente il prodotto o il servizio richiesto.</p>

                        <p>Per le finalità di cui al punto 2 c), il rifiuto di conferimento dei dati e/o di prestare il consenso non comporta alcuna conseguenza sui rapporti giuridici in essere o sulle trattative in corso a richiesta dell’interessato, ma preclude all’interessato stesso la possibilità di ricevere informazioni e promozioni commerciali di prodotti e/o servizi dell’azienda.</p>

                        <p>Per le finalità di cui al punto 2 d), il rifiuto di prestare il consenso e/o la rimozione dei cookies può influenzare l’utilizzo del sito Web e, se il computer sul quale si disattivano i cookies è condiviso, tale rimozione influirà su tutti gli utenti che impiegano tale computer.</p>

                        <p className='sub-header'>10. Diritti dell’interessato</p>

                        <p>L’interessato a cui si riferiscono i dati personali ha il diritto in qualsiasi momento di chiedere la conferma che sia o meno in corso un trattamento dei medesimi dati e, in tal caso, di ottenere l’accesso ai medesimi dati ai sensi dell’art. 15 del Regolamento UE n. 679/2016 (GDPR); ha anche diritto di chiedere la rettifica e/o la cancellazione degli stessi o la limitazione del trattamento dei dati personali che lo riguardano e di opporsi al loro trattamento ai sensi degli artt. 16, 17, 18 del GDPR, cit: ha inoltre diritto alla portabilità dei dati stessi ai sensi dell’art. 20 del GDPR, cit.</p>

                        <p>L’interessato che ha prestato il consenso al trattamento dei loro dati personali, ove il consenso sia previsto e richiesto, può revocare tale consenso in qualsiasi momento, senza far venir meno la legittimità del trattamento basato sul consenso prima della revoca.</p>

                        <p>Lo stesso soggetto cui si riferiscono i dati trattati ha il diritto di proporre reclamo alla competente Autorità di controllo (ad es., in Italia, al Garante per la protezione dei dati personali).</p>

                        <p>Le richieste / comunicazioni vanno rivolte al Titolare del trattamento ai seguenti riferimenti:</p>

                        <p>STARSYSTEM IT Srl – Località Fratte 49 – c/o CENTRO COMMERCIALE PONTE REGIO – Pergine Valsugana (TN) – Italy | N. Iscr. Reg. Imprese, P.iva e Cod. Fisc. 00698480225 – Capitale Sociale Euro 10.000,00 i.v. Tel. : 0461.185.10.40, Fax: : 0461.019.979 Email : info@starsystem.biz | Posta PEC: <a href='mailto:cert@pec.starsystem.biz'>cert@pec.starsystem.biz</a></p>

                        <p><span className='modal-underline'>L’elenco aggiornato dei responsabili esterni del trattamento</span> è consultabile sul nostro sito internet al <a href='https://www.starsystem.biz/elenco-dei-responsabili-del-trattamento-dati/'>seguente indirizzo</a>.</p>
                        
                    </div>
                </div>
                <div className='cookie-footer'>
                    <div className='footer-center'>
                        <div className='footer-cookie-text'>
                            By continuing to browse or by closing this window, you accept the use of cookies.
                        </div>
                        <div className='modal-button' onClick={onCloseModal}>
                            Continue to browse
                        </div>
                    </div>
                </div>
            </div>
        )
    }

   
}


export default ModalText

import React from 'react';
import { TimelineMax, Linear } from 'gsap';
import './chartSvg.scss';

export default class ChartSvg extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.start !== prevProps.start) {
            this.startAnimationSmooth();
        }
      }

    startAnimationSmooth() {
        const { vertecies } = this.props;
        const tl = new TimelineMax({ paused: true });
        const lineGreenAnimation = new TimelineMax();

        const delay = 0.5;
        const delayGreen = 0.5;

        const randomYGreen = vertecies[0];
        const randomYBlue = vertecies[1];

        const timeEndBlock = 0;

        for (let i = 1; i < 6; i++) {
            const line = document.getElementById('line-blue-' + i);
            const lineNext =
                i < 5 ? document.getElementById('line-blue-' + (i + 1)) : null;

            const lineGreen = document.getElementById('line-green-' + i);
            const lineGreenNext =
                i < 5 ? document.getElementById('line-green-' + (i + 1)) : null;

            const circle = document.getElementById('circle-blue-' + (i + 1));
            const circleGreen = document.getElementById('circle-green-' + (i + 1));

            // add first circle
            if (i === 1) {
                const circleBlue = document.getElementById('circle-blue-1');
                tl.to(
                    circleBlue,
                    delay,
                    {
                        attr: {
                            cy: randomYBlue[1]
                        },
                        ease: Linear.easeNone
                    },
                    timeEndBlock
                ).to(
                    line,
                    delay,
                    {
                        attr: {
                            y1: randomYBlue[1]
                            // y2: randomYBlue[i + 1]
                        },
                        ease: Linear.easeNone
                    },
                    timeEndBlock
                );

                const circleGreen = document.getElementById('circle-green-1');
                lineGreenAnimation
                    .to(
                        circleGreen,
                        delayGreen,
                        {
                            attr: {
                                cy: randomYGreen[1]
                            },
                            ease: Linear.easeNone
                        },
                        timeEndBlock
                    )
                    .to(
                        lineGreen,
                        delayGreen,
                        {
                            attr: {
                                y1: randomYGreen[1]
                                // y2: randomYBlue[i + 1]
                            },
                            ease: Linear.easeNone
                        },
                        timeEndBlock
                    );
            }

            tl.to(
                line,
                delay,
                {
                    attr: {
                        // y1: randomYBlue[i]
                        y2: randomYBlue[i + 1]
                    },
                    ease: Linear.easeNone
                },
                timeEndBlock
            ).to(
                circle,
                delay,
                {
                    attr: {
                        cy: randomYBlue[i + 1]
                    },
                    ease: Linear.easeNone
                },
                timeEndBlock
            );

            if (lineNext) {
                tl.to(
                    lineNext,
                    delay,
                    {
                        attr: {
                            y1: randomYBlue[i + 1]
                            // y2: randomYBlue[i + 1]
                        },
                        ease: Linear.easeNone
                    },
                    timeEndBlock
                );
            }

            lineGreenAnimation
                .to(
                    lineGreen,
                    delayGreen,
                    {
                        attr: {
                            // y1: randomYBlue[i]
                            y2: randomYGreen[i + 1]
                        },
                        ease: Linear.easeNone
                    },
                    timeEndBlock
                )
                .to(
                    circleGreen,
                    delayGreen,
                    {
                        attr: {
                            cy: randomYGreen[i + 1]
                        },
                        ease: Linear.easeNone
                    },
                    timeEndBlock
                );

            if (lineGreenNext) {
                lineGreenAnimation.to(
                    lineGreenNext,
                    delayGreen,
                    {
                        attr: {
                            y1: randomYGreen[i + 1]
                            // y2: randomYBlue[i + 1]
                        },
                        ease: Linear.easeNone
                    },
                    timeEndBlock
                );
            }
        }

        tl.play();
    }

    render() {
        const { start, className } = this.props;
        if(start) this.startAnimationSmooth();
        return (
            <div className={className}>
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    width="100%"
                    viewBox="0 0 320 145"
                // xml:space="preserve"
                >
                    {/* <style type="text/css">
      .st0 {
        fill: none;
        stroke: #246cd9;
        stroke-width: 3;
      }
      .st1 {
        fill: white;
        stroke: #246cd9;
        stroke-width: 3;
      }
      .line-green {
        stroke: #65db2d;
        stroke-width: 3;
      }
      .line-greed {
        stroke: #3e7de1;
        opacity: 0.2;
        stroke-width: 2;
      }
    </style> */}
                    <line
                        id="line-blue-1"
                        x1="23.93"
                        y1="132.02"
                        x2="76.17"
                        y2="53.91"
                        className="st0"
                    />
                    <line
                        id="line-blue-2"
                        x1="76.17"
                        y1="53.91"
                        x2="148.17"
                        y2="82.59"
                        className="st0"
                    />
                    <line
                        id="line-blue-3"
                        x1="148.17"
                        y1="82.59"
                        x2="214.63"
                        y2="33.84"
                        className="st0"
                    />
                    <line
                        id="line-blue-4"
                        x1="214.63"
                        y1="33.84"
                        x2="256.17"
                        y2="78.02"
                        className="st0"
                    />
                    <line
                        id="line-blue-5"
                        x1="256.17"
                        y1="78.02"
                        x2="300.17"
                        y2="63.98"
                        className="st0"
                    />

                    <circle id="circle-blue-1" className="st1" cx="20.76" cy="131.38" r="4.64" />
                    <circle id="circle-blue-2" className="st1" cx="76.51" cy="54.32" r="4.64" />
                    <circle id="circle-blue-3" className="st1" cx="148.24" cy="81.98" r="4.64" />
                    <circle id="circle-blue-4" className="st1" cx="214.7" cy="33.84" r="4.64" />
                    <circle id="circle-blue-5" className="st1" cx="256.24" cy="77.85" r="4.64" />
                    <circle id="circle-blue-6" className="st1" cx="300.17" cy="64.11" r="4.64" />

                    <line
                        id="line-green-1"
                        x2="68.8454"
                        y2="86.5019"
                        x1="20.923828"
                        y1="106.8812"
                        className="line-green"
                    />
                    <line
                        id="line-green-2"
                        x1="68.8454"
                        y1="86.5019"
                        x2="112.0821"
                        y2="97.8186"
                        className="line-green"
                    />
                    <line
                        id="line-green-3"
                        x2="158.162"
                        y2="55.0714"
                        x1="112.0821"
                        y1="97.8186"
                        className="line-green"
                    />
                    <line
                        id="line-green-4"
                        x1="158.162"
                        y1="55.0714"
                        x2="201.207"
                        y2="86.5805"
                        className="line-green"
                    />
                    <line
                        id="line-green-5"
                        x2="301.249"
                        y2="30.922363"
                        x1="201.207"
                        y1="86.5805"
                        className="line-green"
                    />

                    <circle
                        id="circle-green-1"
                        cx="20.923828"
                        cy="106.88"
                        r="5"
                        fill="#00D63D"
                        stroke="#00D63D"
                        strokeWidth="2"
                    />

                    <circle
                        id="circle-green-2"
                        cx="68.8454"
                        cy="86.5019"
                        r="5"
                        fill="#00D63D"   
                        stroke="#00D63D"
                        strokeWidth="2"
                    />
                    <circle
                        id="circle-green-3"
                        cx="112.0821"
                        cy="97.8186"
                        r="5"
                        fill="#00D63D"
                        stroke="#00D63D"
                        strokeWidth="2"
                    />
                    <circle
                        id="circle-green-4"
                        cx="158.162"
                        cy="55.0714"
                        r="5"
                        fill="#00D63D"
                        stroke="#00D63D"
                        strokeWidth="2"
                    />
                    <circle
                        id="circle-green-5"
                        cx="201.207"
                        cy="86.5805"
                        r="5"
                        fill="#00D63D"
                        stroke="#00D63D"
                        strokeWidth="2"
                    />
                    <circle
                        id="circle-green-6"
                        cx="301.249"
                        cy="30.922363"
                        r="5"
                        fill="#00D63D"
                        stroke="#00D63D"
                        strokeWidth="2"
                    />
                </svg>
            </div>
        )
    }
}
import React from 'react';

const lib = {
    map(value, inMin, inMax, outMin, outMax) {
        return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    },
    range(start, end, tick) {
        const s = Math.round(start / tick) * tick;
        return Array.from(
            {
                length: Math.floor((end - start) / tick)
            },
            (v, k) => {
                return k * tick + s;
            }
        );
    }
};

export default class SvgWaveSvg extends React.Component {


    initPoints = () => {
        const { points, o, svg } = this.props;
        if (!points) return;

        return points.map(e => {
            const x = lib.map(e[0], o.xMin, o.xMax, 0, svg.w);
            const y = lib.map(e[1], o.yMin, o.yMax, svg.h, 0);
            return [x, y];
        });
    }

    initStyles = () => {
        const { d } = this.props;
        return {
            path: {
                fill: d.colors.path,
                stroke: d.colors.path,
                strokeWidth: 2,
                fillOpacity: 1,
                strokeOpacity: 1
            },
            circles: {
                fill: d.colors.circles
            }
        };
    }

    initPathD = (pointsPositions) => {
        const { svg } = this.props;
        return pointsPositions.reduce(
            (acc, e, i, a) =>
                i === 0
                    ? `M ${a[a.length - 1][0]},${svg.h}
    L ${e[0]},${svg.h} L ${e[0]},${e[1]}`
                    : `${acc} ${this.bezierCommand(e, i, a)}`,
            ''
        );
    }

    line(pointA, pointB) {
        const lengthX = pointB[0] - pointA[0];
        const lengthY = pointB[1] - pointA[1];
        return {
            length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
            angle: Math.atan2(lengthY, lengthX)
        };
    }

    controlPoint(current, previous, next, reverse) {
        const { o } = this.props;
        const p = previous || current;
        const n = next || current;
        const o1 = this.line(p, n);
        // work in progress…
        const flat = lib.map(
          Math.cos(o1.angle) * o.line.flattening,
          0,
          1,
          1,
          0
        );
        const angle = o1.angle * flat + (reverse ? Math.PI : 0);
        const length = o1.length * o.line.smoothing;
        const x = current[0] + Math.cos(angle) * length;
        const y = current[1] + Math.sin(angle) * length;
        return [x, y];
      }

    bezierCommand(point, i, a) {
        const cps = this.controlPoint(a[i - 1], a[i - 2], point);
        const cpe = this.controlPoint(point, a[i - 1], a[i + 1], true);
        const close = i === a.length - 1 ? ' z' : '';
        return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${
            point[1]
            }${close}`;
    }

    render() {
        const pointsPositions = this.initPoints();
        let pathD = [];

        if (pointsPositions) {
            pathD = this.initPathD(pointsPositions);
        }
        const styles = this.initStyles();

        return (
            <g>
                <path style={styles.path} d={pathD}></path>
                {!pointsPositions ? false : pointsPositions.map((item, i) => {
                    return (
                        <circle
                            key={'l' + i}
                            cx={item[0]}
                            cy={item[1]}
                            r="2.5"
                            style={styles.circles}
                        />
                    )
                })}
            </g >
        )
    }
}
import React from 'react';
import Swipe from 'react-easy-swipe';
import './slider.scss';
import feedBackDataCode from './dataForSlider';

export default class Slider extends React.Component {

    state = {
        feedBack: feedBackDataCode,
        data: null,
        feedPosition: 0
    }

    componentWillMount() {
        this.setState({
            data: this.state.feedBack[0],
        });

    }

    lastStarClass = (ratio) => {
        if (ratio > 4.9) return 'star';
        else if (ratio > 4) return 'star-half';
        else return 'star-empty';
    }

    changeFeedBack = (index) => {
        let { feedPosition, feedBack, data } = this.state;
        const element = document.getElementById('slider-block');
        element.classList.remove('anim-text-right');
        element.classList.remove('anim-text-left');
        if (index === feedPosition) {
            return;
        }

        feedPosition = index;
        data = feedBack[feedPosition];
        setTimeout(() => {
            this.setState({
                data,
                feedPosition,
            });
            if (index >= feedPosition) {
                element.classList.add('anim-text-left');
            } else {
                element.classList.add('anim-text-right');
            }
        }, 50);
    }
    moveNext = () => {
        let { feedBack, feedPosition, data } = this.state;
        const element = document.getElementById('slider-block');
        element.classList.remove('anim-text-right');
        element.classList.remove('anim-text-left');
        setTimeout(() => {
            const len = feedBack.length;
            feedPosition = (feedPosition + 1) % len;
            data = feedBack[feedPosition];
            this.setState({
                feedPosition,
                data,
                moveLeft: true,
                moveRight: false,
            });
            element.classList.add('anim-text-left');
        }, 50);

    }
    movePrev = () => {
        let { feedBack, feedPosition, data } = this.state;
        const element = document.getElementById('slider-block');
        element.classList.remove('anim-text-right');
        element.classList.remove('anim-text-left');
        setTimeout(() => {
            const len = feedBack.length;
            feedPosition = (feedPosition > 0 ? --feedPosition : len - 1) % len;
            data = feedBack[feedPosition];
            this.setState({
                feedPosition,
                data,
                moveLeft: false,
                moveRight: true,
            })
        element.classList.add('anim-text-right');
        }, 50);

    }
    roundVal = (val) => {
        return parseFloat(val).toFixed(1);
    }

    countRatio = () => {
        const { data } = this.state;
        let props = 0;
        let len = 0;
        for (let prop in data.score) {
            len += 1;
            props += data.score[prop];
        }
        return parseFloat(props / len).toFixed(1);
    }

    render() {
        const { data, feedBack, feedPosition } = this.state;
        const ratio = this.countRatio();
        return (
            <div style={{ display: "flex", alignItems: "center" }} className="slider-container">

                <div className="footer-review-outer">
                    <div className="arrow-left" onClick={this.movePrev}></div>
                    <Swipe className='swipe-container'
                            onSwipeLeft={this.moveNext}
                            onSwipeRight={this.movePrev}
                    >
                    <div
                       
                        className={"footer-review"}
                        style={{ position: "relative", top: "-25px" }}
                    >
                        <div style={{ display: "flex", width: "500px" }} className="slider-header">
                            <div className="fead-icon">
                                <img src={data.imgFlag} alt='Flag' className='fead-icon-img'/>
                            </div>
                            <div>
                                <div className="fead-head">{data.whoFirst}</div>
                                <div className="fead-head">{data.whoLast}</div>
                                {/* dcnfdrf */}
                                <div className='footer-stars fs-small'>
                                <div style={{ display: "flex", alignItems: "baseline" }} className="slider-description slider-small">
                                    <div className="mark-1">{ratio}</div>
                                    <div className="star"></div>
                                    <div className="star"></div>
                                    <div className="star"></div>
                                    <div className="star"></div>
                                    <div className={this.lastStarClass(ratio)}></div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-stars-container">
                            <div className="footer-stars fs-big">
                                <div className="slider-description slider-big">
                                    <div className="mark-1">{ratio}</div>
                                    <div className="star"></div>
                                    <div className="star"></div>
                                    <div className="star"></div>
                                    <div className="star"></div>
                                    <div className={this.lastStarClass(ratio)}></div>
                                </div>
                                <div className="slider-awards"
                                    style={{ display: "flex", justifyContent: "space-between", width: "470px", fontSize: "14px" }}
                                >
                                    <div className="assessment">
                                        <span className="slider-text">Quality</span>
                                        <span className="mark-2">{this.roundVal(data.score.quality)}</span>
                                    </div>
                                    <div className="assessment">
                                        <span className="slider-text">Schedule</span>
                                        <span className="mark-2">{this.roundVal(data.score.schedule)}</span>
                                    </div>
                                    <div className="assessment">
                                        <span className="slider-text">Cost</span>
                                        <span className="mark-2">{this.roundVal(data.score.cost)}</span>
                                    </div>
                                    <div className="assessment">
                                        <span className="slider-text">Willing to refer</span>
                                        <span className="mark-2">{this.roundVal(data.score.willingToRefer)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                            <div className="footer-text-block" id="slider-block">
                                <div className="footer-text-head">
                                    {data.textHead}
                                </div>
                                <div className="footer-text-descr">
                                    {data.description}
                                </div>
                            </div>
                        
                    </div >
                     </Swipe>
                    <div className="arrow-right" onClick={this.moveNext} ></div >
                </div >
                <div className="pagination-my">
                    {feedBack.map((item, i) => {
                        return (
                            <div key={i + 1} className={feedPosition === i ? "page-footer selected" : "page-footer"}
                                onClick={() => this.changeFeedBack(i)}></div>
                        )
                    })}
                </div >

            </div >
        );
    }
}
